import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { setAuthHeader } from '../../../config/api';
import { loginAction, otpVerification, resendOTPAction, } from '../../../redux/actions/auth_action';
import { dispatchAction } from '../../../redux/actions/common_action';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
import Loader from '../../loader/loader';
import './otp.css';
import Footer from '../../landing-page/Footer/Footer';


import { v4 as uuidv4 } from 'uuid';
import { setAuthHeaderProd } from '../../../config/prod-api';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getProfileData } from '../../../redux/actions/dashboard_action';
import { getClientConfiguration } from '../../../redux/actions/b2b_action';
const Logo = require("../../../images/assets/Logo.png");

function OTP() {
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [userExists, setUserExists] = useState();
    const [resendOtp, setResendOtp] = useState()
    const navigate = useNavigate();
    const [initOnboarArrayObj, setInitOnboarArrayObj] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [otpResponse, setOtpResponse] = useState({});
    const [otpReferenceId, setOtpReferenceId] = useState(null);
    const [disableResendBtn, setDisableResendBtn] = useState(false);
    var isCallOnce = true;
    // UseScriptCode('https://embed.array.io/cms/array-web-component.js?appKey=3F03D20E-5311-43D8-8A76-E4B5D77793BD');
    // UseScriptCode('https://embed.array.io/cms/array-account-enroll.js?appKey=3F03D20E-5311-43D8-8A76-E4B5D77793BD');
    const modalRef = useRef(null);

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        setDisableResendBtn(false)
        if (user?.access_token) {
            navigate('/dashboard');
        } else if (state === null) { }

        if (state !== null) {
            setOtpReferenceId(state.otpReferenceId)
        }

    }, [])

    useEffect(() => {
        console.log("isCallOnce " + isCallOnce);
        //    window.addEventListener('array-event', arrayEventHandler);
    }, []);



    // Attach the event listener

    const openModal = () => {
        setModalOpen(true);
    };


    useEffect(() => {
        if (isModalOpen && modalRef.current) {
            const timer = setTimeout(() => {
                // Scroll the modal content by 100 pixels vertically after 2 seconds
                modalRef.current.scrollTop = 80; // Adjust the pixel value as needed
            }, 200);
            // Cleanup the timeout on unmount or when `isOpen` changes
            return () => clearTimeout(timer);
        }
    }, [isModalOpen]);

    const closeModal = () => setModalOpen(false);
    const resendOTPDisable = {
        opacity: disableResendBtn ? 0.5 : 1.0,
    };
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);



    const verifyOtp = () => {

        setUserExists('')
        setShowError(false)
        if (!otp || otp === '') {
            setShowError(true)
        } else {
            const param = {
                // "mobileNumber": state.mobileNumber,
                "email": state.emailId,
                "otp": otp,
                "otpReferenceId": otpReferenceId
            }
            setLoader(true)
            otpVerification(param).then(resp => {
                console.log("OTP ", resp)
                if (resp.statusCode === "OTP_VERIFIED") {


                    AsyncStorage.setItem('token', resp.access_token)
                    setAuthHeader(resp.access_token);
                    setAuthHeaderProd(resp.access_token);
                    dispatch(dispatchAction(SIGN_IN_SUCCESS, resp, false));
                    getCommonUATClientTokenB2B(
                        () => {
                            getClientConfiguration().then((resp) => {
                                if (resp?.allConfiguration && resp.statusCode === "SUCCESS") {
                                    const jsonString = JSON.stringify(resp?.allConfiguration);
                                    // Store the JSON string in local storage
                                    localStorage.setItem('allConfiguration', jsonString);
                                }
                            }).catch((error) => {
                            }).finally(() => {

                            });
                        },
                        (errorMessage) => {
                        }
                    );
                    //GET Profile Information of client
                    getProfileData().then((resp) => {
                        if (resp.statusCode === "SUCCESS") {
                            localStorage.setItem('fullName', resp.fullName)
                            localStorage.setItem('email', resp.email)
                            localStorage.setItem('businessName', resp.businessName)
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });

                    setTimeout(() => {
                        setLoader(false)
                        navigate('/users');
                    }, 2000);


                } else {
                    dispatch(dispatchAction(SIGN_IN_ERROR, resp.data.error, true));
                }
            }).catch(error => {

                setLoader(false)
                setUserExists(error?.response.data.errorMessage)
                dispatch(dispatchAction(SIGN_IN_ERROR, error.message, true));

                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode === "OTP_VERIFY_ATTEMPTS_EXCEEDED") {
                    //// DISABLE BUTTON ONLY RESEND
                    setDisableResendBtn(true);
                }

            });
        }
    }

    // const login = () => {
    //     setResendOtp('')
    //     if (state.emailId === undefined || state.emailId === '') {
    //     } else {
    //         const param = {
    //             "email": state.emailId,

    //         };

    //         setLoader(true)
    //         loginAction(param).then(resp => {
    //             setLoader(false)
    //             setSeconds(30)
    //             setOtpReferenceId(resp.otpReferenceId)
    //             setResendOtp("Resend OTP")
    //         }).catch(error => {
    //             setLoader(false)
    //         })
    //     }
    // }

    const resendOTPApi = () => {
        setShowError('')
        setOtp('')
        setResendOtp('')
        setUserExists('');
        setDisableResendBtn(false)
        if (state.emailId === undefined) {
        } else {
            const param = {
                "email": state.emailId,
                "otpReferenceId": otpReferenceId
            };
            setLoader(true)
            resendOTPAction(param).then(resp => {
                setLoader(false)
                if (resp && resp.statusCode === "OTP_SENT") {
                    setSeconds(60);
                    setOtpReferenceId(resp.otpReferenceId)
                }
                //setResendOtp("Resend OTP")
            }).catch(error => {
                setLoader(false)

                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode === "RESEND_OTP_ATTEMPT_EXCEEDED") {
                    //// DISABLE BUTTON ONLY RESEND
                    setDisableResendBtn(true);
                }

                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode === "FREQUENT_RESEND") {
                    setSeconds(60);
                    setDisableResendBtn(false);
                }

                /// SHOWING GENERAL MSG ON ERROR SCENARIO
                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode != "FREQUENT_RESEND") {
                    setUserExists(error?.response.data.errorMessage);
                } else {
                    if (error?.response.data.errorCode != "FREQUENT_RESEND") {
                        setUserExists("Something went wrong!");
                    }
                }
            })
        }
    }



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            verifyOtp()
        }
    };
    const btnEnableDisable = {
        opacity: otp == '' || otp.length !== 6
            ? 0.5 : 1.0,
    };

    return (

        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0  form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className='text-start '>
                                        OTP Verification
                                    </h1>
                                    <p className='mt-md-3'>
                                        Enter the OTP you would have received on your given email {state && state.emailId ? `${state.emailId}` : ''} to continue
                                    </p>
                                    <div className='form-input-box otp-box'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className='mb-1 mb-md-2'>
                                                    <label className='label-label'>6 Digit otp </label>
                                                </div>
                                                <div>
                                                    <input type="tel" className='input-box form-control' minLength="6" maxLength="6" autoComplete='off' value={otp}
                                                        pattern="\d{3}-\d{3}-\d{4}"
                                                        onChange={(e) => {
                                                            setOtp(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    {(showError && (!otp || otp === '')) && <span className="demo-tetx">Please enter your OTP</span>}
                                                    {userExists !== '' && <span className='demo-tetx mt-2'>{userExists}</span>}
                                                    {resendOtp !== '' && <p className='demo-tetx mt-1'>{resendOtp}</p>}
                                                </div>
                                            </div>
                                            <div className='resend-otp-box'>
                                                {seconds !== 0 && <div className='form-inner-box mb-1 my-mb-5'>
                                                    <div className='text-end text-sm-end my-1 my-md-5'>
                                                        <label className='getotp-text'>
                                                            00:{seconds > 9 ? `${seconds}` : `0${seconds}`}
                                                        </label>
                                                    </div>
                                                </div>}
                                                {seconds === 0 && <div className='form-inner-box mb-1 my-mb-5'>
                                                    <div className='text-end text-sm-end my-1 my-md-5'>
                                                        <label className='getotp-text' style={resendOTPDisable} onClick={() => {
                                                            resendOTPApi();
                                                        }}>
                                                            Resend OTP
                                                        </label>
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className='form-inner-box align-items-end my-1 my-md-5 otp-button-box'>
                                                <div className='text-center text-sm-end my-1 my-md-5'>
                                                    <button type='button' className='next-button'
                                                        style={btnEnableDisable}
                                                        onClick={() => {

                                                            verifyOtp();
                                                            //   openModal()
                                                        }}>Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div className='footer-box'>
                <Footer isChatIconShow={false} />
            </div>
        </>
    );
}

export default OTP;