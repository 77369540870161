import '../../../App.css'
import './Scroe-card.css'
import { useEffect, useState } from 'react';
import { creditReport } from '../../../redux/actions/dashboard_action';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getUserData from '../../../Common/helper';
import Footer from '../../landing-page/Footer/Footer';
import BankStatment from '../Bank/bankstatement';
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactDialogBox } from 'react-js-dialog-box';
import { authCode, emailVerification } from '../../../redux/actions/auth_action';
import Insights from './components/insihght/insights-dilog';
import ScoreDetails from './components/score-details/score-range-dailog';
import ErrorMessage from '../error-message/error-message';
import { useRef } from 'react';

const AxisBank = require("../../../images/Bank/Bank.png");
const Score = require("../../../images/assets/Chart.png");
const PaymentsIcon = require("../../../images/assets/Time Machine.png");
const LimitIcon = require("../../../images/assets/Money Box.png");
const AgeIcon = require("../../../images/assets/Schedule.png");
const AccountIcon = require("../../../images/assets/Merchant Account.png");
const EnquiriesIcon = require("../../../images/assets/Query.png");

function ScoreCards(props) {

    const [creditReportData, setCreditReportData] = useState();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [userData, setUserData] = useState();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenDialogS, setIsOpenDialogS] = useState(false);
    const [dialogHeight, setDialogHeight] = useState('auto')
    const [selectBureau, setSelectedBureau] = useState('');
    const [bureauList, setBureauList] = useState([]);
    const [showError, setShowError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [analysisReportData, setAnalysisReportData] = useState();
    const [insightsDialog, setInsightsDialog] = useState(false);
    const [selectedAnalysisName, setSelectedAnalysisName] = useState();
    const [emailErrorMessage, setEmailErrorMessage] = useState();
    const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
    const [message, setMessage] = useState();
    const [isViewAll, setIsViewAll] = useState(true);
    const { state } = useLocation();
    const ref = useRef(null);


    useEffect(() => {
        // setMessage("")
        // if (!user?.access_token) {
        //     navigate('/');
        // } else {
        //     const data = getUserData()
        //     setUserData(data)
        //     getCreditReport();
        //     if (state?.success) {
        //         setMessage({ success: state?.success.statusMessage });
        //     } else if (state?.error) {
        //         setMessage({ error: state?.error.statusMessage });
        //     }
        // }
    }, []);

    useEffect(() => {
        if (window.location.search && window.location.search !== "" && window.location.search.includes("state")) {
            if (!isVerified) {
                setIsVerified(true)
                var data = window.location.search.split("&");
                var state = data[0].split("=")[1];
                var code = data[1].split("=")[1];
                var param = {
                    "oauthCode": code
                }
                authCode(param).then(resp => {
                    setEmailErrorMessage(undefined)
                    if (resp.statusCode === "ERROR") {
                        navigate('/users', { state: { "error": resp.statusMessage } });
                    } else {
                        navigate('/users', { state: { "success": resp.statusMessage } })
                    }
                }).catch(error => {
                    if (error.statusCode === "ERROR") {
                        navigate('/users', { state: { "error": error.statusMessage } });
                    }
                })
            }
        }
    }, []);


    const openInsights = (name) => {
        setSelectedAnalysisName(name);
        setInsightsDialog(true)
        setDialogHeight('auto')
    }
    const openScoreDetails = (name) => {
        setScoreDitailsDialog(true)
        setDialogHeight('auto')
    }

    const openDialog = () => {
        setIsOpenDialogS(true)
    }

    const openDialogs = () => {
        setIsOpenDialogS(true)
    }

    const closeinsght = () => {
        setInsightsDialog(false)
        setDialogHeight('auto')
    }

    const closeScoreDetails = () => {
        setScoreDitailsDialog(false)
        setDialogHeight('auto')
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setDialogHeight('auto')
    }

    const closeDialogS = () => {
        setIsOpenDialogS(false)
        setDialogHeight('auto')
    }

    const submit = async () => {
        setEmailErrorMessage('')
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setShowError(false)
        if (!email || email === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setEmailErrorMessage('Please enter valid email address');
        } else {
            var param = {
                "email": email
            }
            emailVerification(param).then(resp => {
                if (resp.statusCode === "SUCCESS") {
                    window.location = resp.url;
                }
            }).catch(error => {
                setEmailErrorMessage(error?.statusMessage)
            })
        }
    }

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const getCreditReport = (bureau = '') => {
        creditReport(bureau).then((resp) => {
            setCreditReportData(resp.data);
            setAnalysisReportData(resp.analysis)
            bureauDataGenerate(resp?.bureauName ?? '', resp?.availableOtherBureauData ?? []);
        }).catch((error) => {

        })
    }

    const bureauDataGenerate = (bureauName, bureaudata) => {
        setSelectedBureau(bureauName);
        var bureauListData = bureauList;
        if (!bureauListData.includes(bureauName)) {
            bureauListData.push(bureauName)
        }
        bureaudata.forEach(element => {
            if (!bureauListData.includes(element)) {
                bureauListData.push(element)
            }
        });
        setBureauList(bureauListData);
    }

    const addressCreate = (address) => {
        var addresses = [];
        for (let index = 0; index < address.length; index++) {
            addresses.push(
                <div className='row' key={index + "Address"}>
                    <div className='col-4 my-2'>
                        <p className='report-main-text text-start'>{address[index].reportedDate ?? ''}</p>
                    </div>
                    <div className='col-8 my-2'>
                        <p className='report-sub-text text-break text-start'>
                            {address[index].address ?? ''}
                        </p>
                    </div>
                    <div className='border'></div>

                </div>
            )
        }
        return addresses;
    }

    const eamilAddress = (emails) => {
        var emailAddressVariationss = [];
        for (let index = 0; index < emails.length; index++) {
            emailAddressVariationss.push(
                <div key={index + "Emails"}>
                    <p className='report-sub-text text-break text-lowercase'>{emails[index] ?? ''}</p>
                </div>
            )
        }
        return emailAddressVariationss;
    }

    const mobileNumbers = (phoneNumbers) => {
        var mobileNumberVariationss = [];
        for (let index = 0; index < phoneNumbers.length; index++) {
            mobileNumberVariationss.push(
                <div key={index + "Emails"}>
                    <p className='report-sub-text text-break text-lowercase'>{phoneNumbers[index] ?? ''}</p>
                </div>
            )
        }
        return mobileNumberVariationss;
    }

    const accountDetails = (accountess) => {
        var accounts = [];
        for (let index = 0; index < accountess.length; index++) {
            accounts.push(
                <div className="accordion-item my-3" key={index + "Account"}>
                    <div className="accordion-header accordion-button align-items-start pb-2" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                        <div className='row w-100 align-items-start g-2 g-sm-0'>
                            <div className='col-12 col-sm-4'>
                                <div className='d-flex align-items-start'>
                                    <div className='bank-image-box'>
                                        <img src={AxisBank} width={100}></img>
                                    </div>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>{accountess[index].creditAccountInformation.lender ?? "-"}</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className='reported-date'>{accountess[index].creditAccountInformation.accountType ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-5'>
                                {/* <span className='mb-1 progress-text'>50% Payment done</span>
                                <div className='progresssbars'>
                                    <ProgressBar completed={50} labelClassName="labels" barContainerClassName="barcompleted" />
                                </div> */}
                            </div>
                            <div className='col-12 col-sm-3'>
                                <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
                                    {accountess[index].creditAccountDetails.creditLimitAmount !== '' && accountess[index].creditAccountDetails.creditLimitAmount !== '-' && <h1 className='text-start text-sm-end dolars-rs-text mb-0'>$  {accountess[index].creditAccountDetails.creditLimitAmount ?? ""}</h1>}
                                    {/* <div className='credit-report-amount-box float-sm-end float-start'>
                                        <div className='good-stand-box'>Good Standing</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={"collapseOne" + index} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className='row g-3'>
                                <div className='col-lg-6'>
                                    <div className='account-details-box'>
                                        <p className='account-details-text'>Account details</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Account number</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text text-break'>{accountess[index].creditAccountTerms.accountNumber ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Type</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountInformation.accountType ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Status</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.accountStatus ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Activity Reported</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.dateReported ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Date Opened</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Current balance</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.currentBalance ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Amount overdue</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.amountOverdue ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Payment Amount</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.actualPaymentAmount ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Cash Limit</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.cashLimit ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Highest Credit</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.highestCredit ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Last Payment Date</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.lastPaymentDate ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Loan Type</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.loanType ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Suitfiled</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.suitFiled ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Credit Limit Amount</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.creditLimitAmount ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Emi Amount</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.emiAmount ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='account-details-box'>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Principal Write Off</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.principalWriteOff ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Repayment Tenure</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.repaymentTenure ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Settlement Amount</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.settlementAmount ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Total Write Off Amount</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.totalWriteOffAmount ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Written Off Settle Status</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.writtenOffSettleStatus ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Account Holder Name</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountInformation.accountHolderName ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Addresses</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text text-break'>{accountess[index].creditAccountInformation.addresses ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Credit Facility Status</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.creditFacilityStatus ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Date Closed</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.dateClosed ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Date Opened</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Ownership</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.ownership ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Payment End Date</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.paymentEndDate ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Payment Frequency</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.paymentFrequency ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Rate Of Interest</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.rateOfInterest ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>SuitFiled</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.suitFiled ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Type Of Collateral</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.typeOfCollateral ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='text-start'>
                                                        <p className='account-details-main-text'>Value Of Collateral</p>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='text-end'>
                                                        <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.valueOfCollateral ?? "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return accounts;
    }

    const bureauTabs = () => {
        var bureau = [];
        for (let index = 0; index < bureauList.length; index++) {
            bureau.push(
                <button type="button" key={index} className={selectBureau === bureauList[index] ? "btn change-report-btn-active userscore-texts me-1 ms-0" : "btn change-report-btn userscore-texts me-1 ms-0"}
                    onClick={() => {
                        setSelectedBureau(bureauList[index])
                        getCreditReport(bureauList[index]);
                        setIsViewAll(true)

                        var personalInformationCollapseBtn = document.getElementById('personalInformationCollapseButton');
                        personalInformationCollapseBtn.classList.add("collapsed");

                        var panelsStayOpenCollapseTwo = document.getElementById('panelsStayOpen-collapseTwo');
                        panelsStayOpenCollapseTwo.classList.remove('show')

                    }}>{bureauList[index]}</button>
            )
        }
        return bureau;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit()
        }
    };

    const personalInformationAddresViewAll = () => {
        setIsViewAll(false);
        document.getElementById("personalInformation").style.height = "100%";
    }

    const onPersonalInformationExpand = () => {
        if (ref.current != null) {
            document.getElementById("personalInformation").style.height = (ref.current.clientHeight - 19).toString() + "px";
        }
    }

    return (
        <>
            <div className='mt-4 mt-sm-2'>
                <section className='' id='creditscore'>
                    {bureauList.length !== 0 && <div>
                        <div className='tab-box py-2 pb-0'>
                            <div className='container px-0'>
                                <div className='row scorll-tabs g-2'>
                                    <div className='col-12 text-center text-md-end px-0'>
                                        <div className="btn-group px-0" role="group" aria-label="Basic outlined example">
                                            {bureauTabs()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className='container-lg container-fluid mt-3 my-sm-3'>
                        <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                            <div className='col-5'>
                                <div className='ps-sm-2'>
                                    <h1 className='username-text mb-2 mb-sm-3'>Hi {userData?.given_name}  !</h1>
                                    <p className='welcome-back-text mb-1'>Welcome back</p>
                                </div>
                            </div>
                            <div className='col-7'>
                                <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                                    <div className='text-end text-sm-end me-3 me-sm-2'>
                                        <p className='mb-0 userscore-textes'>Your Credit score</p>
                                        <h1 className={creditReportData && creditReportData !== null && creditReportData?.score?.score > 0 ? 'credit-score' : 'credit-score0'} >
                                            {(creditReportData && creditReportData !== null) ? (creditReportData?.score?.score && creditReportData?.score?.score !== null && Number(creditReportData?.score?.score) >= 0) ? creditReportData.score.score : '0' : '---'}
                                        </h1>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                                        <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                                            {(creditReportData?.score?.score >= 780 && creditReportData?.score?.score <= 900) &&
                                                <div className='excellect-box score-box'>
                                                    <p className='mb-0 score-box-text'>Excellent</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 706 && creditReportData?.score?.score <= 779) &&
                                                <div className='goods-box score-box'>
                                                    <p className='mb-0 score-box-text'>Good</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 631 && creditReportData?.score?.score <= 705) &&
                                                <div className='average-box score-box'>
                                                    <p className='mb-0 score-box-text'>Average</p>
                                                </div>}
                                            {(creditReportData?.score?.score <= 630) &&
                                                <div className='poor-box score-box'>
                                                    <p className='mb-0 score-box-text'>Poor</p>
                                                </div>}
                                            {(creditReportData?.score?.score == undefined || creditReportData?.score?.score == null || creditReportData?.score?.score == 0) &&
                                                <div className='score-not-show-box score-box mx-2'>
                                                    <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                                                </div>}
                                        </div>
                                        <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1" onClick={() => { openScoreDetails() }}></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className='crdit-score-dailog'>
                                {scoreDitailsDialog && (
                                    <ReactDialogBox
                                        modalWidth='auto'
                                        closeBox={closeScoreDetails}
                                        headerBackgroundColor='white'
                                        headerTextColor='black'
                                        closeButtonColor='black'
                                        bodyBackgroundColor='white'
                                        bodyTextColor='black'
                                        headerText="Credit Score Range"
                                        bodyHeight={dialogHeight}
                                        borderRadius='8px'
                                        headerHeight='65px'
                                    >
                                        <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                                    </ReactDialogBox>)}
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <ErrorMessage message={message} />
                </section>

                <section id='insights'>
                    <div className='container-lg container-fluid my-3'>
                        {analysisReportData && <div className='insights-main-box'>
                            <div className='row g-2'>
                                <div className='col-12'>
                                    <p className='insights-heding-text'>INSIGHTS</p>
                                    <div className='dividers w-100'></div>
                                </div>
                            </div>
                            <div className='row g-2'>
                                <div className='col-12'>
                                    <p className='ms-md-3 insights-sub-text'>Check out the factors affecting your credit score!</p>
                                </div>
                                <div className='col-12 px-xl-5'>
                                    <div className="row row-cols-2 row-cols-md-5 row-cols-xl-5 g-3 gt-1">
                                        {analysisReportData && analysisReportData.paymentHistory &&
                                            <div className="col insights-main-box-height">
                                                <div className='insights-sub-main-box h-100'>
                                                    <div className="cards h-100">
                                                        <div className="card-bod px-2 py-3">
                                                            <div className='insights-imag-box mb-2'>
                                                                <img src={PaymentsIcon} className='img-fluid' />
                                                            </div>
                                                            <h5 className="card-title insights-box-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.paymentHistory?.label && analysisReportData?.paymentHistory?.label != null) ? analysisReportData.paymentHistory.label : '' : ''}</h5>
                                                            <p className="card-text insights-card-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.paymentHistory?.impactOnScore && analysisReportData?.paymentHistory?.impactOnScore !== null) ? analysisReportData.paymentHistory.impactOnScore : '' : ''}</p>
                                                        </div>
                                                        <div className={(analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('paymentHistory') }}>
                                                            <p className="card-text insights-card-main-text">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.paymentHistory?.information?.title && analysisReportData?.paymentHistory?.information?.title !== null) ? analysisReportData.paymentHistory.information?.title : '' : ''}</p>
                                                            <div className='d-flex justify-content-between align-items-center mt-1'>
                                                                <h5 className={(analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysisReportData?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.paymentHistory?.ratio && analysisReportData?.paymentHistory?.ratio !== null) ? (analysisReportData.paymentHistory.ratio + '%') : '' : ''}</h5>
                                                                <i className="bi bi-chevron-right right-ariw-icons"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {analysisReportData && analysisReportData.accounts &&
                                            <div className="col insights-main-box-height">
                                                <div className='insights-sub-main-box h-100'>
                                                    <div className="cards h-100">
                                                        <div className="card-bod px-2 py-3">
                                                            <div className='insights-imag-box mb-2'>
                                                                <img src={AccountIcon} className='img-fluid' />
                                                            </div>
                                                            <h5 className="card-title insights-box-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.accounts?.label && analysisReportData?.accounts?.label !== null) ? analysisReportData.accounts.label : '' : ''}</h5>
                                                            <p className="card-text insights-card-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.accounts?.impactOnScore && analysisReportData?.accounts?.impactOnScore !== null) ? analysisReportData.accounts.impactOnScore : '' : ''}</p>
                                                        </div>
                                                        <div className={(analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('accounts') }}>
                                                            <p className="card-text insights-card-main-text">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.accounts?.information?.title && analysisReportData?.accounts?.information?.title !== null) ? analysisReportData.accounts.information?.title : '' : ''}</p>
                                                            <div className='d-flex justify-content-between align-items-center mt-1'>
                                                                {analysisReportData?.accounts?.noOfActiveAccounts && <h5 className={(analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysisReportData?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.accounts?.noOfActiveAccounts && analysisReportData?.accounts?.noOfActiveAccounts !== null) ? analysisReportData.accounts.noOfActiveAccounts : '' : ''} Active</h5>}
                                                                <i className="bi bi-chevron-right right-ariw-icons"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {analysisReportData && analysisReportData.creditHistory &&
                                            <div className="col insights-main-box-height">
                                                <div className='insights-sub-main-box h-100'>
                                                    <div className="cards h-100">
                                                        <div className="card-bod px-2 py-3">
                                                            <div className='insights-imag-box mb-2'>
                                                                <img src={AgeIcon} className='img-fluid' />
                                                            </div>
                                                            <h5 className="card-title insights-box-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditHistory?.label && analysisReportData?.creditHistory?.label !== null) ? analysisReportData.creditHistory.label : '' : ''}</h5>
                                                            <p className="card-text insights-card-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditHistory?.impactOnScore && analysisReportData?.creditHistory?.impactOnScore !== null) ? analysisReportData.creditHistory.impactOnScore : '' : ''}</p>
                                                        </div>
                                                        <div className={(analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditHistory') }}>
                                                            <p className="card-text insights-card-main-text">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditHistory?.information?.title && analysisReportData?.creditHistory?.information?.title !== null) ? analysisReportData.creditHistory.information?.title : '' : ''}</p>
                                                            <div className='d-flex justify-content-between align-items-center mt-1'>
                                                                <h5 className={(analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysisReportData?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditHistory?.ageOfAccounts && analysisReportData?.creditHistory?.ageOfAccounts !== null) ? analysisReportData.creditHistory.ageOfAccounts : '' : ''}</h5>
                                                                <i className="bi bi-chevron-right right-ariw-icons"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {analysisReportData && analysisReportData.creditUtilization &&
                                            <div className="col insights-main-box-height">
                                                <div className='insights-sub-main-box h-100'>
                                                    <div className="cards h-100">
                                                        <div className="card-bod px-2 py-3">
                                                            <div className='insights-imag-box mb-2'>
                                                                <img src={LimitIcon} className='img-fluid' />
                                                            </div>
                                                            <h5 className="card-title insights-box-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditUtilization?.label && analysisReportData?.creditUtilization?.label !== null) ? analysisReportData.creditUtilization.label : '' : ''}</h5>
                                                            <p className="card-text insights-card-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditUtilization?.impactOnScore && analysisReportData?.creditUtilization?.impactOnScore !== null) ? analysisReportData.creditUtilization.impactOnScore : '' : ''}</p>
                                                        </div>
                                                        <div className={(analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditUtilization') }}>
                                                            <p className="card-text insights-card-main-text">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditUtilization?.information?.title && analysisReportData?.creditUtilization?.information?.title !== null) ? analysisReportData.creditUtilization.information?.title : '' : ''}</p>
                                                            <div className='d-flex justify-content-between align-items-center mt-1'>
                                                                <h5 className={(analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysisReportData?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.creditUtilization?.ratio && analysisReportData?.creditUtilization?.ratio !== null) ? (analysisReportData.creditUtilization.ratio + '%') : '' : ''}</h5>
                                                                <i className="bi bi-chevron-right right-ariw-icons"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {analysisReportData && analysisReportData.enquiries &&
                                            <div className="col insights-main-box-height">
                                                <div className='insights-sub-main-box h-100'>
                                                    <div className="cards h-100">
                                                        <div className="card-bod px-2 py-3">
                                                            <div className='insights-imag-box mb-2'>
                                                                <img src={EnquiriesIcon} className='img-fluid' />
                                                            </div>
                                                            <h5 className="card-title insights-box-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.enquiries?.label && analysisReportData?.enquiries?.label !== null) ? analysisReportData.enquiries.label : '' : ''}</h5>
                                                            <p className="card-text insights-card-main-title">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.enquiries?.impactOnScore && analysisReportData?.enquiries?.impactOnScore !== null) ? analysisReportData.enquiries.impactOnScore : '' : ''}</p>
                                                        </div>
                                                        <div className={(analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('enquiries') }}>
                                                            <p className="card-text insights-card-main-text">{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.enquiries?.information?.title && analysisReportData?.enquiries?.information?.title !== null) ? analysisReportData.enquiries.information?.title : '' : ''}</p>
                                                            <div className='d-flex justify-content-between align-items-center mt-1'>
                                                                <h5 className={(analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysisReportData?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysisReportData && analysisReportData !== null) ? (analysisReportData?.enquiries?.totalNoOfEnquiry && analysisReportData?.enquiries?.totalNoOfEnquiry !== null) ? analysisReportData.enquiries.totalNoOfEnquiry : '' : ''}</h5>
                                                                <i className="bi bi-chevron-right right-ariw-icons"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='insightdialog'>
                                        {insightsDialog && (
                                            <ReactDialogBox
                                                modalWidth='auto'
                                                closeBox={closeinsght}
                                                headerBackgroundColor='white'
                                                headerTextColor='black'
                                                closeButtonColor='black'
                                                bodyBackgroundColor='white'
                                                bodyTextColor='black'
                                                bodyHeight={dialogHeight}
                                                borderRadius='8px'
                                            >
                                                <Insights closeDialog={closeinsght} setDialogHeight={setDialogHeight} setAnalysisReportData={setAnalysisReportData} selectedAnalysisName={selectedAnalysisName} analysisReportData={analysisReportData[selectedAnalysisName]} />
                                            </ReactDialogBox>)}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section >

                {creditReportData?.creditAccountInformationDetails && <section id='summatyaccount'>
                    <div className='container-lg container-fluid my-3'>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                    <div className='w-100'>
                                        <div>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text" >Credit Account Information</p>
                                                <span className='text-end me-2 view-text-all'>View All</span>
                                            </div>
                                        </div>
                                        {creditReportData && creditReportData?.reportSummary && <section>
                                            <div className='border-top'>
                                                <div className='my-2'>
                                                    <div className='row align-items-center g-3'>
                                                        <div className='col-lg-2'>
                                                            <p className='account-summary-text'>Account Summary</p>
                                                        </div>
                                                        <div className='col-lg-10 row g-2'>
                                                            <div className='col-6 col-md-3'>
                                                                <div className='acsummary-box'>
                                                                    <div className='balance-left-border-box'></div>
                                                                    <div className='text-center mx-auto'>
                                                                        <p className='summary-sub-text'>Balance</p>
                                                                        {creditReportData?.reportSummary?.currentBalanceAccountSummary && <h3 className='summary-numbers'>$ {(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount && creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount != null) ? creditReportData.reportSummary.currentBalanceAccountSummary.totalCurrentBalanceAmount : '' : ''}</h3>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-3'>
                                                                <div className='acsummary-box'>
                                                                    <div className='payments-left-border-box'></div>
                                                                    <div className='text-center mx-auto'>
                                                                        <p className='summary-sub-text'>Payments</p>
                                                                        <h3 className='summary-numbers'> -</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-3'>
                                                                <div className='acsummary-box'>
                                                                    <div className='open-left-border-box'></div>
                                                                    <div className='text-center mx-auto'>
                                                                        <p className='summary-sub-text'>Open Accounts</p>
                                                                        <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts && creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.activeAccounts : '' : ''}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 col-md-3'>
                                                                <div className='acsummary-box'>
                                                                    <div className='closed-left-border-box'></div>
                                                                    <div className='text-center mx-auto'>
                                                                        <p className='summary-sub-text'>Closed Accounts</p>
                                                                        <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts && creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.closedAccounts : '' : ''}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>}
                                    </div>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='account-accordion-box'>
                                                    <div className="accordion " id="accordionFlushExample">
                                                        {creditReportData?.creditAccountInformationDetails && accountDetails(creditReportData.creditAccountInformationDetails)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }

                {creditReportData?.personalInformation && <div className='container-lg container-fluid my-3' id='accountinfo'>
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header mb-0">
                                <button id='personalInformationCollapseButton' className="accordion-button acc-info-text" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-controls="panelsStayOpen-collapseTwo"
                                    onClick={() => {
                                        onPersonalInformationExpand()
                                    }}>
                                    Personal Information
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    <div className='row g-2 personal-infomation-dialog-box'>
                                        <div className='col-md-6'>
                                            {creditReportData && creditReportData?.personalInformation &&
                                                <div className='right-side-border-box' ref={ref} id='PersonalInfo'>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <i className="bi bi-person person-icon"></i>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>BIO</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    <div className='row my-2 '>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Name</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.name && creditReportData?.personalInformation?.name != null) ? creditReportData.personalInformation.name : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>DOB</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.dateOfBirth && creditReportData?.personalInformation?.dateOfBirth != null) ? creditReportData.personalInformation.dateOfBirth : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>PAN</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.pan && creditReportData?.personalInformation?.pan != null) ? creditReportData.personalInformation.pan : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Passport</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.passportNumber && creditReportData?.personalInformation?.passportNumber != null) ? creditReportData.personalInformation.passportNumber : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Email id</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            {creditReportData?.personalInformation.emailAddressVariations && eamilAddress(creditReportData.personalInformation.emailAddressVariations)}
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Contact </p>
                                                        </div>
                                                        <div className='col-8'>
                                                            {creditReportData?.personalInformation.mobileNumberVariations && mobileNumbers(creditReportData.personalInformation.mobileNumberVariations)}
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    {/* <div className='row mb-0 mt-3'>
                                                    <div className='col-12 col-sm-7 offset-sm-4 offset-xl-5 row align-items-center justify-content-between'>
                                                        <div className='col-6'>
                                                            <div>
                                                                <a className='mb-0 view-profile-info-text'>View All</a>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 text-end'>
                                                            <div className='text-center'>
                                                                <button className='btn edit-button' onClick={() => { openDialog() }}>Edit</button>
                                                            </div>
                                                        </div>
                                                        <div className='edit-address-dilog-box'>
                                                            {isOpenDialog && (
                                                                <ReactDialogBox
                                                                    closeBox={closeDialog}
                                                                    headerBackgroundColor='white'
                                                                    headerTextColor='black'
                                                                    headerHeight='65px'
                                                                    closeButtonColor='black'
                                                                    bodyBackgroundColor='white'
                                                                    bodyTextColor='black'
                                                                    bodyHeight={dialogHeight}
                                                                    headerText="Edit PersonaL Information"
                                                                    borderRadius='8px'
                                                                >
                                                                    <EditAddress closeDialog={closeDialog} setDialogHeight={setDialogHeight} />
                                                                </ReactDialogBox>)}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>}

                                        </div>
                                        {/* <div className="collapse" id="collapseExampleOne">
                                            <div className="card card-body">
                                                Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                            </div>
                                        </div>
                                        <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExampleOne" aria-expanded="false" aria-controls="collapseExampleOne">
                                            Button with data-bs-target
                                        </button> */}
                                        <div className='col-md-6'>
                                            <div className='personal-details-addres-box' id='personalInformation'>
                                                {creditReportData && creditReportData?.personalInformation?.addressVariations && <div className='p-3'>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <i className="bi bi-house-door person-icon"></i>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>Address</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>

                                                    {creditReportData?.personalInformation?.addressVariations && addressCreate(creditReportData.personalInformation.addressVariations)}

                                                    {/* <div className='row mb-0 mt-3'>
                                                    <div className='col-12 col-sm-7 offset-sm-4 offset-xl-5 row align-items-center justify-content-between'>
                                                        <div className='col-6'>
                                                            <div>
                                                                <a className='mb-0 view-profile-info-text'>View All</a>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 text-end'>
                                                            <div className='text-center '>
                                                                <button className='btn edit-button' onClick={() => { openDialogs() }}>Update</button>
                                                            </div>
                                                        </div>
                                                        <div className='edit-address-dilog-box'>
                                                            {isOpenDialogS && (
                                                                <ReactDialogBox
                                                                    closeBox={closeDialogS}
                                                                    headerBackgroundColor='white'
                                                                    headerTextColor='black'
                                                                    headerHeight='65px'
                                                                    closeButtonColor='black'
                                                                    bodyBackgroundColor='white'
                                                                    bodyTextColor='black'
                                                                    bodyHeight={dialogHeight}
                                                                    headerText="Add New Address"
                                                                    borderRadius='8px'
                                                                >
                                                                    <AddNewAddress closeDialog={closeDialog} setDialogHeight={setDialogHeight} />
                                                                </ReactDialogBox>)}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>}
                                            </div>
                                            {creditReportData && creditReportData?.personalInformation?.addressVariations && <div>
                                                {isViewAll && <div className='row mb-0 mt-3'>
                                                    <div className='col-12  row '>
                                                        <div className='text-center'>
                                                            <p className="mb-0 view-profile-info-text" onClick={() => { personalInformationAddresViewAll() }}>View All</p>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div >

            <div className='container-lg container-fluid'>
                <div className='row my-2 g-2 align-items-center'>
                    <div className='col-sm-12'>
                        <div className='boxes'>
                            <div className='row g-2 align-items-start'>
                                <div className='col-sm-12 col-md-4'>
                                    <div className='row text-start'>
                                        <div className='col-2'>
                                            <div className='mt-1'>
                                                <img src={Score} className='' width='45' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <h1 className='boost-score-text ms-3 ms-sm-2'>Get your credit score</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 text-start col-md-8'>
                                    <div className='score-buttton-box box-score-credit-box'>
                                        <input type='text' className='form-control input-box' placeholder='Enter Your Email Here' value={email}
                                            onKeyDown={handleKeyDown} onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                        <div className='mobile-view-error'>
                                            {(showError && (!email || email === '')) && <span className="demo-tetx text-light">Please enter your email</span>}
                                            {emailErrorMessage !== '' && <span className="demo-tetx mt-2 text-light">{emailErrorMessage}</span>}
                                        </div>
                                        <button className='score-btn credit-score-button'
                                            onClick={() => { submit() }}
                                        >Get Credit Score</button>
                                    </div>
                                    <div className='dashboard-error-view'>
                                        {(showError && (!email || email === '')) && <span className="demo-tetx text-light">Please enter your email</span>}
                                        {emailErrorMessage !== '' && <span className="demo-tetx mt-2 text-light">{emailErrorMessage}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BankStatment setCreditReportData={setCreditReportData} setAnalysisReportData={setAnalysisReportData} bureauDataGenerate={bureauDataGenerate} setMessage={setMessage} scrollToTop={props.scrollToTop} />
            {/* <Banking /> */}

            <Footer />
        </>
    );
}

export default ScoreCards;