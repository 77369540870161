import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ShareURLDialogContent = ({ link }) => (
    <>
        <p className='p-0 m-0 my-2 ps-1' style={{ fontWeight: 'bold', color: "#344054" }}>Share URL</p>
        <div className="info-details-box row p-0 m-0 px-2 py-2 me-3"
            style={{
                overflowX: 'scroll',
                overflowY: 'scroll'
            }}>
            <p className='p-0 m-0 ps-1' style={{ fontWeight: 'light', color: "#727272" }}>{link}</p>
        </div>
        <div className="me-3 px-1 mt-4 mb-2">
            <CopyToClipboard text={link}>
                <div className="upload-statement-btn text-center cursor-pointer">Copy URL</div>
            </CopyToClipboard>
        </div>
    </>
);

export default ShareURLDialogContent;
