import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { validateEmail } from '../../../redux/actions/user_action';
import { setAuthHeader } from '../../../config/api';
import { dispatchAction } from '../../../redux/actions/common_action';
import { registerUser } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import './createanaccount.css';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
import InputMask from 'react-input-mask';
const Logo = require("../../../images/assets/Logo.png");

function CreateAccount() {
    const [bussinessname, setBussinessName] = useState('');
    const [fullname, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [passwrd, setPasswrd] = useState('');
    const { state } = useLocation();
    const [isAcceptTerm, setIsAccepTerm] = useState(false)
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [mobileNumber, setMobileNumber] = useState('');
    const [fillupdetails, setFillupdetails] = useState();
    const [existEmail, setExistEmail] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (user?.access_token) {
            navigate('/users');
        }
    }, [])

    const loginPage = () => {
        navigate('/login');
    }
    const validateMobileNo = (mobileNo) => {
        setMobileNumber(mobileNo = mobileNo.replace(/[^0-9-]/g, ''))
        if (mobileNo) {
            var usMobile = mobileNo.split('-').join('');
            setMobileNumber(usMobile);
        }
    }

    let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleEmailChange = (e) => {
        const lowerEmail = e.target.value.toLowerCase();
        setEmail(lowerEmail);
        setUserExists("");
    };

    const verifyEmail = (emailId) => {
        if (!rex.test(emailId) && emailId !== '') {
            setUserExists("Please enter valid email address");
        } else {
            const param = {
                "email": emailId
            }

            validateEmail(param).then(resp => {
                if (resp.statusCode === "USER_EXISTS") {
                    setUserExists(resp.statusMessage);
                    setExistEmail(true)
                } else {
                    setExistEmail(false)
                    save()
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }


    const save = () => {
        setUserExists('');
        setFillupdetails('');
        setShowError(false);

        if (fullname === '' || email === '' || mobileNumber === '' || bussinessname === '' ||
            mobileNumber.length !== 10 || existEmail) {
            setShowError(true)
        } else if (!rex.test(email)) {
            setUserExists('Please enter valid email address');
        } else {

            navigate('/set-password', {
                state: {
                    step1Data: {
                        name: fullname,
                        bussinessname: bussinessname,
                        mobileNumber: mobileNumber,
                        email: email,
                    },
                    from: 'register'
                }
            });


        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            save()
        }
    };

    const btnEnableDisable = {
        opacity: fullname === '' || bussinessname === ''
            || (email === '' || !rex.test(email)) || existEmail || (mobileNumber.length !== 10 || mobileNumber === '') ? 0.5 : 1.0
    };


    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-2'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                {/* <h1 >
                                    Start your <span className='boost-text'>boost</span> journey
                                </h1> */}
                                <h1 className='color'>
                                    Start your Business Journey with Boost.Money!
                                </h1>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-8 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Create An Account
                                    </h1>
                                    <div className='form-input-box mt-md-4'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Name</div>
                                                </div>
                                                <div>
                                                    <input type="text"
                                                        placeholder='Your first & last name'
                                                        name='fullname' id='fullname' className='input-box form-control' value={fullname} onChange={(e) => { setFullName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && fullname === '') && <span className="demo-tetx py-0 my-0">Please enter your full name</span>}
                                                </div>
                                            </div>
                                            <div className='mt-3'></div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Email</div>
                                                </div>
                                                <div>
                                                    <input type="text"
                                                        placeholder='name@example.com'
                                                        id='email' className='input-box form-control'
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                        onBlur={(e) => verifyEmail(e.target.value)} />
                                                    {(showError && email === '') && <span className="demo-tetx">Please enter your email</span>}
                                                    {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                                </div>
                                            </div>
                                            <div className='mt-3'></div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>Phone</label>
                                                </div>
                                                <div>
                                                    <InputMask
                                                        type="tel"
                                                        mask="999-999-9999"
                                                        maskChar=''
                                                        className='input-box form-control'
                                                        value={mobileNumber}
                                                        onChange={(e) => {
                                                            validateMobileNo(e.target.value)
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                        autoComplete='off'
                                                        placeholder='+1 (415) 333-6182'
                                                    ></InputMask>


                                                    {(showError && mobileNumber === '') && <span className="demo-tetx">Please enter your mobile number</span>}
                                                    {(showError && (mobileNumber.length !== 10 && mobileNumber !== '')) && <span className="demo-tetx">Please enter valid mobile number</span>}

                                                </div>
                                            </div>
                                            <div className='mt-3'></div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Business Name

                                                    </div>
                                                </div>
                                                <div>
                                                    <input type="text"
                                                        placeholder='Business Name'
                                                        name='bussinessname' id='bussinessname' className='input-box form-control' value={bussinessname} onChange={(e) => { setBussinessName(e.target.value) }} onKeyDown={handleKeyDown} />

                                                </div>
                                                {(showError && bussinessname === '') && <span className="demo-tetx">Please enter business name</span>}
                                            </div>


                                            <div className='form-inner-box'>
                                                <div className='align-items-center p-0 m-0 d-flex flex-row justify-content-between 
                                                         px-1 px-md-0 px-sm-0 px-lg-0'>
                                                    <div className='order-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span className='any-account-text'>Have an account? </span><span className='login-text' onClick={() => {
                                                                loginPage()
                                                            }}>Login</span>
                                                        </div>
                                                    </div>
                                                    <div className='order-2'>
                                                        <div className='text-center text-sm-center text-md-end'>
                                                            <button type='button'
                                                                style={btnEnableDisable}
                                                                className='next-button mx-0' onClick={() => {
                                                                    save()
                                                                }} >
                                                                Continue
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default CreateAccount;