import React, { useState } from 'react';
import SelectableInfoBox from './selectable_gen_req_dialog';


const GenReqDialogContent = (props) => {
    const [selectedBox, setSelectedBox] = useState(null);

    return (
        <div>
            {props.clientAllConfig.lendingCopilot && <SelectableInfoBox
                title="Generate AI Lending Copilot URL"
                description="Generate & share a URL to collect Loan Documents"
                isSelected={selectedBox === 'copilot'}
                onClick={() => setSelectedBox('copilot')}
            />}
            <div className='mt-3'>
                <SelectableInfoBox
                    title="Generate SDK URL"
                    description="Generate & share a URL to get Credit Report & Bank Data"
                    isSelected={selectedBox === 'sdk'}
                    onClick={() => setSelectedBox('sdk')}
                />
            </div>

            <div className="me-3 px-1 mt-4 mb-2">
                <div
                    className="upload-statement-btn text-center cursor-pointer"
                    style={{ opacity: selectedBox == null ? '0.5' : '1.0' }}
                    onClick={() => selectedBox && props.handleGenerateURL(selectedBox)}
                >
                    Generate URL
                </div>
            </div>
        </div>
    );
};



export default GenReqDialogContent;
