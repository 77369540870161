import React from 'react';

function Tab({ index, activeIndex, text, onClick }) {
    const isActive = index === activeIndex;

    return (
        <div className={isActive ? 'tab-active' : 'tab-inactive'} onClick={() => onClick(index)}>
            <p className={isActive ? 'tab-text-active two-line-text' : 'tab-text-inactive two-line-text'}>{text}</p>
        </div>
    );
}

export default function Tabs({ activeIndex, onTabChange }) {
    return (
        <section className='d-flex flex-row align-items-center justify-content-center mt-0 '>
            <div className='tabs-parent'>
                <Tab index={0} activeIndex={activeIndex} text="Credit Report Information" onClick={onTabChange} />

                <div className='ms-2 me-2'></div>
                <Tab index={1} activeIndex={activeIndex} text="Bank Account Information" onClick={onTabChange} />
            </div>
        </section>
    );
}