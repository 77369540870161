/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import "./sliding-panel.css";
// import { getAllTemplateApi, sendCopilateApi } from "../../../../redux/actions/b2b_action";
// import { getCommonUATClientTokenB2B } from "../../../Component/GenetateUATClientTokenB2B";
import { toast } from "react-toastify";

const UserListIC = require("../../../images/assets/user-list-ic.png");
const Close = require("../../../images/assets/close.png");
const CLOSE_TIMEOUT = 500;

function useUpdateStateIfMounted(initialValue) {
    const isMountedRef = useRef(true);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const [state, setState] = useState(initialValue);

    const setStateIfMounted = (value) => {
        if (isMountedRef.current) {
            setState(value);
        }
    };

    return [state, setStateIfMounted];
}

export function SlidingPanel({
    isOpen = false,
    title,
    panelData,
    subtitle,
    onRequestClose,
    callAPITamplateID,
    onPassLink,
    onAfterOpen,
    onAfterClose,
    children,
    className = "",
    overlayClassName = "",
    closeIcon,
    from = "right",
    width = window.innerWidth <= 768 ? "90%" : "80%",
    shouldCloseOnEsc,
    hideHeader = false,
}) {
    const directionClass = `slide-pane_from_${from}`;
    const [wasOpen, setWasOpen] = useUpdateStateIfMounted(false);

    const handleAfterOpen = () => {
        onAfterOpen?.();
        setTimeout(() => {
            setWasOpen(true);
        }, 0);
    };

    const handleAfterClose = () => {
        onAfterClose?.();
        setTimeout(() => {
            setWasOpen(false);
        }, 0);
    };




    return (
        <Modal
            ariaHideApp={false}
            overlayClassName={{
                base: `slide-pane__overlay ${overlayClassName}`,
                afterOpen: wasOpen ? "overlay-after-open" : "",
                beforeClose: "overlay-before-close",
            }}
            className={{
                base: `slide-pane ${directionClass} ${className}`,
                afterOpen: wasOpen ? "content-after-open" : "",
                beforeClose: "content-before-close",
            }}
            style={{
                content: { width },
            }}
            closeTimeoutMS={CLOSE_TIMEOUT}
            isOpen={isOpen}
            shouldCloseOnEsc={shouldCloseOnEsc}
            onAfterOpen={handleAfterOpen}
            onAfterClose={handleAfterClose}
            onRequestClose={onRequestClose}
            contentLabel={`Modal "${title || ""}"`}>
            {!hideHeader && (
                <div className="slide-pane__header">
                    <div className="col-12 row d-flex flex-row align-items-center p-0 m-0">
                        <div className="d-flex col-3 align-items-start p-0 m-0 cursor-pointer">
                            <InfoRow date={subtitle} name={title}></InfoRow>
                        </div>
                        <div className="d-flex col-9 p-0 m-0 align-items-center justify-content-end">
                            {/* <div className="d-flex flex-row m-0 align-items-center justify-content-center me-3">
                                <div className='dropdown-toggle-a cursor-pointer'
                                    onClick={(e) => {
                                        callAPITamplateID(panelData);
                                    }}>Send AI Copilot</div>

                            </div> */}
                            <div className="slide-pan-user-ic-outfit me-3 d-none d-lg-flex d-md-flex">
                                <img src={Close} onClick={onRequestClose} alt='Close' className='slide-pan-close-ic' />
                            </div>
                        </div>
                    </div>

                    {/* <div className="slide-pane__title-wrapper">
                        <h2 className="slide-pane__title">{title}</h2>
                        <div className="slide-pane__subtitle">{subtitle}</div>
                    </div> */}
                </div>
            )}
            <div className="slide-pane__content">{children}</div>
        </Modal>
    );
}

function IconClose() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 22">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4 11l8 8c.6.5.6 1.5 0 2-.5.6-1.5.6-2 0l-9-9c-.6-.5-.6-1.5 0-2l9-9c.5-.6 1.5-.6 2 0 .6.5.6 1.5 0 2l-8 8z"
            />
        </svg>
    );
}

const InfoRow = ({ date, name }) => (
    <div className='d-flex align-items-start justify-content-center ps-4'>
        <div className="slide-pan-user-ic-outfit d-none d-lg-flex d-md-flex">
            <img src={UserListIC} alt='UserListIC' className='slide-pan-user-ic ' />
        </div>

        <div className='ps-2'>
            <div className='align-items-center justify-content-center p-0 m-0'>
                <p className='bank-name-text p-0 m-0'>{name}</p>
            </div>
            <div className='align-items-center justify-content-center p-0 m-0'>
                <h1 className='reported-date mb-0 p-0  pt-1'>{date}</h1>
            </div>
        </div>
    </div>
);

export default SlidingPanel;
