import React from "react";
import styled from "styled-components";
import "./filter-component.css";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 44px;
  width: 280px !important;
  border-left: 0px solid #D1D1D1;
  border-bottom: 1px solid #D1D1D1;
  border-top: 1px solid #D1D1D1;
  border-right: 0px solid #D1D1D1;
  border-radius: 0px 0px 0px 0px;
  padding: 0 16px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  font-size: 16px;
  outline: none;
  // &:focus {
  //   box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  //   background-color: #ffffff;
  // }
`;

const ClearButton = styled.button`
  border-left: 0px solid #D1D1D1;
  border-bottom: 1px solid #D1D1D1;
  border-top: 1px solid #D1D1D1;
  border-right: 1px solid #D1D1D1;
  border-radius: 0 8px 8px 0;
  height: 44px;
  width: 40px;
  background-color: #f4f6f8;
  color: #212121;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f4f6f8;
  }
`;


const SearchIcon = styled.button`
  border-left: 1px solid #D1D1D1;
  border-bottom: 1px solid #D1D1D1;
  border-top: 1px solid #D1D1D1;
  border-right: 0px solid #D1D1D1;
  border-radius: 8px 0px 0px 8px;
  height: 44px;
  width: auto;
  background-color: #ffffff;
  color: #212121;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ffffff;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="search-container">
    <button className="search-icon p-0 m-0">
      <span><i className="bi bi-search  p-0 m-0 ps-2"></i></span>
    </button>
    <input
      id="search"
      type="text"
      placeholder="Search"
      value={filterText}
      onChange={onFilter}
      className="input-field"
    />
    <button className="clear-button p-0 m-0" onClick={onClear}>✕</button>
  </div>
);



export default FilterComponent;
