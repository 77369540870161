import React from 'react';

const SelectableInfoBox = ({ title, description, isSelected, onClick }) => {
    return (
        <div
            className="info-details-box row p-0 m-0 px-2 py-2 me-3"
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            <p
                className='p-0 m-0 my-2 ps-1'
                style={{ fontWeight: 'bold', color: isSelected ? '#4243F1' : '#344054' }}
            >
                {title}
            </p>
            <p
                className='p-0 m-0 ps-1'
                style={{ fontWeight: 'light', color: isSelected ? '#4243F1' : '#727272' }}
            >
                {description}
            </p>
        </div>
    );
};

export default SelectableInfoBox;
