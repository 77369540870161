import React from 'react';
import { ReactDialogBox } from 'react-js-dialog-box'
const CustomDialog = ({
    isOpen,
    closeBox,
    headerText,
    headerBackgroundColor = 'white',
    headerTextColor = 'black',
    headerHeight = '65px',
    closeButtonColor = 'black',
    bodyBackgroundColor = 'white',
    bodyTextColor = 'black',
    bodyHeight,
    borderRadius = '8px',
    children,
}) => {
    if (!isOpen) return null;

    return (
        <div className='document-dialog'>
            <ReactDialogBox
                closeBox={closeBox}
                headerBackgroundColor={headerBackgroundColor}
                headerTextColor={headerTextColor}
                headerHeight={headerHeight}
                closeButtonColor={closeButtonColor}
                bodyBackgroundColor={bodyBackgroundColor}
                bodyTextColor={bodyTextColor}
                bodyHeight={bodyHeight}
                headerText={headerText}
                borderRadius={borderRadius}>
                {children}
            </ReactDialogBox>
        </div>
    );
};

export default CustomDialog;
