import React, { useState, useRef, forwardRef } from "react";
import withClickOutside from "./WithClickOutside";
import './download-report-btn.css'
import DLReportOptionItems from "./dl-report-option-items";

const DL_IC = require('../../../images/assets/dl-ic.png');
const SHARE_IC = require('../../../images/assets/share_link.png');
const FILE_IC = require('../../../images/assets/file.png');

const DropdownHamburger = require("../../../images/assets/dropdown-hamburger.png");

const DownloadReportBtn = forwardRef(
    ({ showDropDown, setShowDropDown, showBTNDownload, showAICopilet, showBTNDownloadExcel, showBTNDocs, isRegisterUser, clientAllConfig, commonOnClick }, ref) => {

        const commonClick = (clickFrom) => {
            setShowDropDown(!showDropDown);
            commonOnClick(clickFrom);
        }

        return (
            <section ref={ref}>
                <div className="dropdown">
                    {/* <div className='download-btn-dropdown dropdown-toggle'
                    onClick={() => setShowDropDown(!showDropDown)}
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}>
                    {btnLabel}
                </div> */}
                    <div className="p-0 m-0">
                        <img src={DropdownHamburger} alt="DropdownHamburger"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}
                            id="dropdownMenuButton"
                            onClick={() => setShowDropDown(!showDropDown)}
                            className="drop-down-hamburger" />
                    </div>
                    <div className={"dropdown-menu" + (showDropDown ? " show" : "")} aria-labelledby="dropdownMenuButton">
                        <div className="dl-report-options">

                            {showBTNDownload && <div className="dl-report-item-group"
                                onClick={() => commonClick("DOWNLOAD_REPORT")}>
                                <img src={DL_IC} alt="DL_IC" className="dl-report-ic" />
                                <div className="ps-2">
                                    <p className="dl-report-label">
                                        Boost Report
                                    </p>
                                    <p className="dl-report-subline">
                                        Consolidated credit and boost
                                        report in PDF format.
                                    </p>
                                </div>
                            </div>}

                            {showBTNDownload && <div className="dl-break-line"></div>}
                            {showBTNDownloadExcel && <div className="dl-report-item-group"
                                onClick={() => commonClick("DOWNLOAD_EXCEL")}>
                                <img src={DL_IC} alt="DL_IC" className="dl-report-ic" />
                                <div className="ps-2">
                                    <p className="dl-report-label">
                                        Excel Report
                                    </p>
                                    <p className="dl-report-subline">
                                        List of all your bank transaction
                                        in Excel format.
                                    </p>
                                </div>
                            </div>}



                            {showBTNDownloadExcel && showAICopilet && <div className="dl-break-line"></div>}
                            {clientAllConfig.lendingCopilot && showAICopilet && <div className="dl-report-item-group"
                                onClick={() => commonClick("AI_LENDING_URL")}>
                                <img src={SHARE_IC} alt="DL_IC" className="dl-report-ic" />
                                <div className="ps-2">
                                    <p className="dl-report-label">
                                        AI Lending Copilot URL
                                    </p>
                                    <p className="dl-report-subline">
                                        Generate & share a URL to collect loan documents
                                    </p>
                                </div>
                            </div>}

                            {clientAllConfig.lendingCopilot && showAICopilet && <div className="dl-break-line"></div>}
                            {showAICopilet && <div className="dl-report-item-group" onClick={() =>
                                commonClick("SDK_REQ_URL")}>
                                <img src={SHARE_IC} alt="DL_IC" className="dl-report-ic" />
                                <div className="ps-2">
                                    <p className="dl-report-label">
                                        SDK Request URL
                                    </p>
                                    <p className="dl-report-subline">
                                        Generate & share a URL to get Credit Report & Bank Data
                                    </p>
                                </div>
                            </div>
                            }
                            {clientAllConfig.lendingCopilot && showBTNDocs && <div className="dl-break-line"></div>}
                            {clientAllConfig.lendingCopilot && showBTNDocs && <div className="dl-report-item-group"
                                onClick={() => commonClick("SUPPORTING_DOCS")}>
                                <img src={FILE_IC} alt="DL_IC" className="dl-report-ic" />
                                <div className="ps-2">
                                    <p className="dl-report-label">
                                        Supporting Documents
                                    </p>
                                    <p className="dl-report-subline">
                                        List of documents required for loan application
                                    </p>
                                </div>
                            </div>}


                        </div>
                    </div>
                </div>
            </section>
        );
    });

export default withClickOutside(DownloadReportBtn);