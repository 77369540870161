import React from 'react';
import './video-card.css';

const VideoCard = ({ image, description, title, onVideoClick }) => {
    return (
        <div className="col-sm-12 col-lg-4 col-md-4 p-0 m-0 px-2 py-2" onClick={() => {
            onVideoClick();
        }}>
            <div className='vid-card py-3 px-2'>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="demo-vid-thumb-outbox">
                        <img src={image} alt={title} className="demo-vid-thumb" />
                    </div>
                    <div className="pt-2 text-center">
                        <p className="how-to-use p-0 m-0">{description}</p>
                        <h4 className="demo-video-title p-0 m-0">{title}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoCard;
