import httpClient, { setAuthHeader } from "../../config/api";
import config from './../../config.json'
import { LOGOUT, } from "../constants";
import AsyncStorage from '@react-native-async-storage/async-storage';

const loginAction = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/login',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const registerUser = userInfo =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/register',
      data: userInfo,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const otpVerification = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/b2b/portal/user/otp/verify',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error);
      });
  });

const emailVerification = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/google/oauth/url',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response);
      });
  });

const authCode = data =>
  new Promise((resolve, reject) => {

    httpClient({
      method: 'POST',
      url: 'api/v1/user/google/oauth/code',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const logOut = () => dispatch => {
  return new Promise(async (resolve, rejects) => {
    try {
      dispatch({
        type: LOGOUT,
        subtype: 'SUCCESS'
      });
      await AsyncStorage.removeItem('token');
      await AsyncStorage.removeItem('b2btoken');
      await AsyncStorage.removeItem('b2bProdToken');
      localStorage.removeItem('clientId');
      localStorage.removeItem("fullName")
      localStorage.removeItem("email")
      localStorage.removeItem("businessName")
      setAuthHeader()
      resolve(true);
    } catch (error) {
      rejects(false);
    }
  });
};

const credSDKApiCall = (data, pathname, isiframe) =>
  new Promise((resolve, reject) => {
    var loadUrl = pathname === "/demo" ? config.prodApiUrl : config.apiUrl;
    httpClient({
      method: 'POST',
      url: loadUrl + 'api/v1/b2b/get/sdk/url',
      data: data,
      headers: { 'x-is-iframe': isiframe }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error.response);
    }).catch(onError => {
      reject(onError);
    });;
  });
const resendOTPAction = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/b2b/resend/otp',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });


export {
  loginAction,
  logOut,
  registerUser,
  otpVerification,
  emailVerification,
  authCode,
  credSDKApiCall,
  resendOTPAction

}