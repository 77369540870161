import React, { useState } from 'react';
import Tabs from './tabs';
import '../tab-component/tab-component.css'
import { useNavigate } from 'react-router-dom';


export default function TabComponent(props) {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    const changeTabIndex = (i) => {
        props.onTabChange(i)

        setTabIndex(i);
    }

    return (
        <Tabs activeIndex={tabIndex ?? 0} onTabChange={changeTabIndex} />
    );
}