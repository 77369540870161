import '../../../../App.css';
import '../../Bank/bankstatement.css';
import '../../../Component/list-docs/list-docs.css'
import { v4 as uuidv4 } from 'uuid';
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-js-dialog-box/dist/index.css'
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreditReport from '../../credit-report/credit-report';
import BankStatmentForm from '../../../boost-score/bank-statement/bank-statement';
import CreateCustomer from '../../customer/create-customer'
import { getSDKProdUrl, getUserProdList } from '../../../../redux/actions/prod_action';
import ErrorMessage from '../../error-message/error-message';
import {
    reportDownload, downloadExcelReport, getClientConfiguration, downloadExcelAnalysisReport,
    gstExcelDownloadProdApi, getAllTemplateProdApi, sendCopilateProdApi, downloadLendingDocumentProdAPI,
    documentUploadedProdList,
} from '../../../../redux/actions/prod-b2b_action';
import moment from 'moment/moment';
import Loader from '../../../loader/loader';
import './prod-users.css'

import AsyncStorage from '@react-native-async-storage/async-storage';

import config from '../../../../config.json';
import getSDKError from '../../../../Common/getSDKError';
import DownloadReportBtn from '../../../Component/download-report-btn/download-report-btn.js'
import BankStatmentMultiple from '../../../boost-score/bank-statement/bank-statement-multiple.js';
import '../../../Component/dialogs/react-responsive-modal.css';
import { Modal } from 'react-responsive-modal';
import DataTable from 'react-data-table-component';
import PaginationComponent from '../../../Component/pagination/pagination-component.js';
import { toast } from 'react-toastify';
import SuccessFailDialog from '../../../Component/dialogs/success-fail-dialog.js';
import GSTUploadDocuments from '../../gst-report/gst-uploadstatement.js';
import ChatIcon from '../../../Boost-AI/Chat-Icon/chat-icon.js';
import getUserData from '../../../../Common/helper.js';
import FilterComponent from '../../../Component/searchbar/filter-component.js';
import TabComponent from '../../../Component/tab-component/tab-component.js';
import VideoCard from '../../../Component/vid-card/video-card.js';
import Tooltip from '../../../Component/tool-tip.js';
import CustomDialog from '../../../Component/custom-dialog/custom-dialog.js';
import ShareURLDialogContent from '../../../Component/custom-dialog/share-url-dialog-content.js';
import SelectableInfoBox from '../../../Component/custom-dialog/selectable_gen_req_dialog.js';
import GenReqDialogContent from '../../../Component/custom-dialog/genreq_dialog_content .js';
import ListDocComponent from '../../../Component/list-docs/list-docs.js';
import SlidingPanel from '../../silding-component/sliding-panel.js';
import SubTabComponent from '../../sub-tab-component/sub-tab-component.js';
import CreditTabComponent from '../../credit-tab-component/credit-tab-component.js';
import BoostTabComponent from '../../bank-tab-component/boost-tab-component.js';
import { getCommonProdClientTokenB2B } from '../../../Component/GenetateProdClientTokenB2B.js';

const AddBank = require("../../../../images/assets/AddBank.png");
const GetInsights = require("../../../../images/assets/Getinsights.png");
const Finances = require("../../../../images/assets/Finances.png");
const UserListIC = require("../../../../images/assets/user-list-ic.png");
const DownArrowIC = require("../../../../images/assets/down_arrow.png");
const UploadIC = require("../../../../images/assets/upload_ic.png");
const DownloadIC = require("../../../../images/assets/dwnl_ic.png");
const ShareLink = require("../../../../images/assets/link-share.png");

const RTArrowWT = require("../../../../images/assets/right-arrow-wt.png");
function ProdUsersList(props) {

    const [isOpenDialog, setIsOpenDialog] = useState(false);   // Manage Credit Report Component
    const [isOpenTestUserDialog, setIsOpenTestUserDialog] = useState(false) // Manage Test User Report Component
    const [isOpenBankStmDialog, setIsOpenBankStmDialog] = useState(false) // Manage Bank Statement Component
    const [dialogTestUserTitle, setDialogTestUserTitle] = useState('Enter Customer Name')
    const [dialogTitle, setDialogTitle] = useState('Upload Credit Report')
    const [dialogBankStmTitle, setDialogBankStmTitle] = useState('Upload Bank Statement')
    const [userListDataFilter, setUserListDataFilter] = useState()
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [openLinkDialog, setOpenLinkDialog] = useState({
        "isOpen": false, "link": "",
        "label": ''
    });

    const [isOpenGenReqURLDialog, setOpenGenReqURLDialog] = useState({
        isOpen: false,
        dataRow: {
            "businessUserId": null,
            "firstName": null,
            "lastName": null,
            "mobileNumber": null,
            "email": null,
        }
    });
    const [listOfDocShow, setListOfDocShow] = useState({ "isOpenPopup": false, "docBusinessUserId": "" });
    const [showVideo, setShowVideo] = useState({ "isOpenPopup": false, "link": "", "title": "" });
    const [userListData, setUserListData] = useState()
    const [demoUserListData, setDemoUserListData] = useState()
    const [demoClientToken, setDemoClientToken] = useState(null)
    const [businessId, setBusinessId] = useState()
    const [isShowSkipBtn, setIsShowSkipBtn] = useState(true)
    const [message, setMessage] = useState();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [shouldToggle, setShouldToggle] = useState(false);
    const [openMultipleScreen, setOpenMultipleScreen] = useState(false);
    const [openGSTDialog, setOpenGSTDialog] = useState(false);
    const [listOfDocs, setDocOfList] = useState(null);
    const [showGenAI, setShowGenAI] = useState(false) // Manage Gen AI Screen
    /// below var use for paginagtion stuff
    const [sizePerPage, setSizePerPage] = useState(5); /// initial default sizePerPage === 5
    const [totalRecordFromApi, setTotalRecordFromApi] = useState(0);
    const [totalPageFromApi, setTotalPageFromApi] = useState(0);
    const [currentRow, setCurrentRow] = useState(null);
    const [fiuMultiBankSPF, setFiuMultiBankSPF] = useState('');
    const [fiuPartialMsg, setFiuPartialMsg] = useState({ msg: '', uuid: '' });
    const [openResSPFDialog, setOpenStatmentResSPFDialog] = useState(false);
    const [openSDKIframe, setOpenSDKIframe] = useState(false);
    const [b2bLink, setB2BLink] = useState('')
    const [callSDKB2B, setCallSDKB2B] = useState(false);
    const [userData, setUserData] = useState();
    let [clientAllConfig, setClientAllConfig] = useState({});
    const [panelData, setPanelData] = useState({
        'businessUserId': null, "isOpnePanel": false,
        "fullName": null, "date": null, 'firstName': null,
        'lastName': null,
        'mobileNumber': null,
        'salariedEmployeeTemplateId': null,
        'selfEmployeeTemplateId': null
    });
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    let sdkMessage = '';

    const navigate = useNavigate();
    const { state } = useLocation();

    const [dialogHeight, setDialogHeight] = useState("auto");

    const { user } = useSelector((state) => {
        return state.authReducer;
    });




    useEffect(() => {
        if (!user?.access_token) {
            navigate("/");
        } else {
            const data = getUserData()
            setUserData(data)
        }
        setLoader(true)
        getCommonProdClientTokenB2B(
            () => {
                getClientConfiguration().then((resp) => {
                    if (resp?.allConfiguration && resp.statusCode === "SUCCESS") {
                        setClientAllConfig(resp?.allConfiguration);

                    }
                }).catch((error) => {
                }).finally(() => {
                    setTotalRecordFromApi(0)
                    setTotalPageFromApi(0)
                    /// initially 0 page no pass to API


                    getMainUserList(0);

                });
            },
            (errorMessage) => {
                setLoader(true)
            }
        );
        var sdkError = getSDKError()
        setMessage(sdkError)
    }, []);

    const scrollToUser = () => {
        const websdkSection = document.getElementById('userslist');
        if (websdkSection) {
            websdkSection.scrollIntoView({
                top: websdkSection.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    // const handleClick = (e) => {
    //   const isDownloadBtnChild = e.target.closest('.download-btn-dropdown');
    //   const isDropdownToggleChild = e.target.closest('.dropdown-toggle-a');
    //   if (isDownloadBtnChild || isDropdownToggleChild) {
    //     setShouldToggle(false);
    //     return;
    //   }
    //   setShouldToggle(true);
    // }

    // For Test User
    const openTestUser = () => {
        generateClientTokenUATB2B(false, false, null);
    };

    const closeTestUserDialog = () => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        setDialogTestUserTitle("Enter Customer Name");
        setDialogHeight("auto");
        //props.scrollToTop()
    };


    const openGSTUpload = (businessid) => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        setBusinessId(businessid);
        setDialogTitle("Upload GST Documents");
        setOpenGSTDialog(true);
    };
    // For Credit Report
    const openCreditReport = (businessid) => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(true);
        setBusinessId(businessid);
        setIsShowSkipBtn(false);
        setOpenGSTDialog(false);
    };

    const closeDialog = () => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        // setIsOpenBankStmDialog(false)
        setDialogTitle("Upload Credit Report");
        setDialogHeight("auto");
        setOpenGSTDialog(false);
        //scrollToTop()
    };

    // For Bank Statement
    const openBankStatement = (businessid) => {
        setDialogBankStmTitle("Upload Bank Statements");
        setIsOpenBankStmDialog(true);
        setBusinessId(businessid);
        setIsShowSkipBtn(false);
        setOpenGSTDialog(false);

    };

    const closeBankStmDialog = () => {
        setIsOpenBankStmDialog(false);
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        setDialogBankStmTitle("Upload Bank Statement");
        setDialogHeight("auto");
        setOpenGSTDialog(false);
        //props.scrollToTop()
    };

    const openMultipleBankStatement = () => {
        setIsOpenBankStmDialog(false);
        setDialogBankStmTitle("Upload Multiple Banks");
        setIsShowSkipBtn(true);
        setDialogHeight("550px");
        setOpenGSTDialog(false);
        setOpenMultipleScreen(true)
    };

    const closeMultiBankStmDialog = () => {
        setIsOpenBankStmDialog(false);
        setIsOpenTestUserDialog(false);
        setOpenMultipleScreen(false)
        setIsOpenDialog(false);
        setDialogHeight("auto");

        //props.scrollToTop()
    };

    const openStatmentResSPFDialog = () => {
        closeMultiBankStmDialog();
        setOpenStatmentResSPFDialog(true)
    }

    const closeStatmentResSPFDialog = () => {
        setOpenStatmentResSPFDialog(false)
        setTotalRecordFromApi(0)
        setTotalPageFromApi(0)
        /// initially 0 page no pass to API
        getMainUserList(0);

    }

    const downloadLendingDocs = (businessId) => {

        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId
        }
        const uuid = uuidv4();

        getCommonProdClientTokenB2B(
            () => {
                downloadLendingDocumentProdAPI(param, uuid)
                    .then(response => {
                        closeListOfDocPopup()
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                        // Create a temporary <a> element to initiate the download
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'lendig-documents.zip';
                        document.body.appendChild(a);
                        a.click();
                        // Cleanup the temporary URL and <a> element
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the PDF file:', error);
                    })

            },
            (errorMessage) => {
                closeListOfDocPopup();
                setLoader(false);
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const closeListOfDocPopup = () => {
        setListOfDocShow({ "isOpenPopup": false, "docBusinessUserId": "" });
    }


    const closeVideoPopup = () => {
        setShowVideo({ "isOpenPopup": false, "link": "", "title": '' });
    }

    const showListOFDocs = (bID) => {
        setLoader(true);
        const param = {
            businessUserId: bID
        }
        const uuid = uuidv4();

        getCommonProdClientTokenB2B(
            () => {
                documentUploadedProdList(param, uuid)
                    .then(response => {
                        if (response.statusCode == 'NO_DOCUMENTS_UPLOADED') {
                            setDocOfList([]);
                        } else if (response.statusCode == 'SUCCESS') {
                            setDocOfList(response.documents)
                        }
                        setListOfDocShow({ "isOpenPopup": true, "docBusinessUserId": bID })
                        //console.log("response documentUploadedList ", response);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the PDF file:', error);
                    })

            },
            (errorMessage) => {
                setLoader(false);
                console.error('Error:', errorMessage); // Handle error
            }
        );

    }


    const handleTabChange = (tabIndex) => {
       // console.log("Tab changed to:", tabIndex);
        setCurrentTabIndex(tabIndex)
        // Perform some action, e.g., update state
    };


    const handleGenerateURL = (row, selectedBox) => {
        // console.log(`Generating URL for ${selectedBox}`);
        // Logic to generate the URL based on the selected option
        if (selectedBox === 'copilot') {
            closeGenLinkDialog();
            /// write code copilot

            getTemplateIDApi(row)
        } else {
            closeGenLinkDialog();
            /// write code SDK
            opneSDKUATUser(false, row);
        }

    };


    const extraProps = {
        openFromBill: false,
    };

    /// onChangePage using arrows for Tabel's paginations
    const handlePerRowsChange = async (pageNo, totalRecord) => {
        if (pageNo > 0) {
            pageNo = pageNo - 1;
        } else {
            pageNo = 0;
        }
        setLoader(true)
        getMainUserList(pageNo, true);
    };

    const getMainUserList = (page, fromPagination = false) => {

        getUserProdList(page, sizePerPage).then((resp) => {
            setLoader(false)

            setUserListData(resp.data)
            setUserListDataFilter(resp.data)
            // console.log("resp.data.totalPage; " + resp?.totalPage + " R " + resp?.totalData)
            // below param use for Paginations   
            setTotalRecordFromApi(resp?.totalData);
            setTotalPageFromApi(resp?.totalPage)


            /// HIDE ELEMENT Class which prevented download button hidden issue fixed
            setTimeout(() => {
                var element = document.querySelector('.fpBGKh');
                if (element) {
                    // Disable attributes
                    for (var i = 0; i < element.attributes.length; i++) {
                        element.removeAttribute(element.attributes[i].name);
                    }
                }
            }, 1000);


            if (!fromPagination) {
                const rootUat = document.querySelector('#sdk');
                rootUat.scrollIntoView({
                    top: rootUat.offsetTop,
                    behavior: 'smooth'
                }, 500)
            } else {
                scrollToUser();
            }


            const prefix = '#userslist';
            if (window.location.hash === prefix) {
                setTimeout(() => {
                    scrollToUser();
                }, 300)
            }
        }).catch((error) => {

        });


    }



    const downloadReport = (businessId, fromDemoUser) => {
        setOpen(false);
        setLoader(true);

        const param = {
            businessUserId: businessId,
        };
        const uuid = uuidv4();

        const handleDownload = (token = null) => {
            reportDownload(param, uuid, token)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'boostreport.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    setLoader(false);
                })
                .catch(error => {
                    setLoader(false);
                    console.error('Error downloading the PDF file:', error);
                });
        };

        if (!fromDemoUser) {
            getCommonProdClientTokenB2B(
                () => handleDownload(),
                (errorMessage) => {
                    setLoader(false);
                    console.error('Error:', errorMessage);
                }
            );
        } else {
            handleDownload(demoClientToken);
        }
    };


    const downloadExcel = (businessId, fromDemoUser) => {
        setOpen(false);
        setLoader(true);

        const param = {
            businessUserId: businessId,
        };
        const uuid = uuidv4();

        const handleDownload = (token = null) => {
            downloadExcelReport(param, uuid, token)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'boost_transaction_report.xls';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    setLoader(false);
                })
                .catch(error => {
                    setLoader(false);
                    console.error('Error downloading the Excel file:', error);
                });
        };

        if (!fromDemoUser) {
            getCommonProdClientTokenB2B(
                () => handleDownload(),
                (errorMessage) => {
                    setLoader(false);
                    console.error('Error:', errorMessage);
                }
            );
        } else {
            handleDownload(demoClientToken);
        }
    };

    const downloadExcelAnalysis = (businessId, accountUID) => {
        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId,
            accountUID: accountUID
        }
        const uuid = uuidv4();
        getCommonProdClientTokenB2B(
            () => {
                downloadExcelAnalysisReport(param, uuid)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'excel_analysis_report_powered_by_FinBit_io.xls';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the Excel file:', error);
                    })
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const handleOpen = () => {
        setOpen(!open);
    };

    const dropdownStatus = open ? "show" : "";


    //// style for data tabels
    const customStyles = {
        rows: {
            style: {
                borderRadius: '8px',
                minHeight: '90px',
                margin: '10px 0px',
                border: '1px solid #CECECE',
                color: 'red', display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',

            },
        },
        cells: {
            style: {
                paddingTop: '12px',
                paddingBottom: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                cursor: 'pointer',
                userSelection: 'none',
                alignItems: 'center',
            },
        },
        table: {
            style: {
                marginBottom: '10px'
            },
        },
    };

    //// cells for data tabels
    const InfoRow = ({ date, name }) => (
        <div className='d-flex align-items-start justify-content-center'>
            <div className='user-ic-back d-none d-md-block d-lg-block d-sm-block'>
                <img src={UserListIC} alt='UserListIC' className='user-ic' />
            </div>

            <div className='ps-2'>
                <div className='align-items-center justify-content-center'>
                    <p className='bank-name-text p-0 m-0'>{name}</p>
                </div>
                <div className='align-items-center justify-content-center'>
                    <h1 className='reported-date mb-0 p-0  pt-1'>{date}</h1>
                </div>
            </div>
        </div>
    );
    const UserName = ({ row }) => (
        <InfoRow date={row.userCreatedDate != null ? moment(row.userCreatedDate).format('DD-MM-YYYY h:mm A') : '-'} name={row.displayName} />
    );
    const DownloadUploadButton = ({ row, fromDemoUser }) => (
        <div className=''>

            <div className='d-flex align-items-center'>



                {!fromDemoUser && <div className='pe-2 d-none d-md-block d-lg-block d-sm-block'>
                    <Tooltip content="Generate & share AI Lending Copilot & SDK URL ">
                        <img src={ShareLink} alt="DropdownHamburger"
                            id="dropdownMenuButton" onClick={() => {
                                setOpenGenReqURLDialog({
                                    isOpen: true,
                                    dataRow: {
                                        "businessUserId": row.businessUserId,
                                        "firstName": row.firstName,
                                        "lastName": row.lastName,
                                        "mobileNumber": row.mobileNumber,
                                        "email": row.email,
                                    }
                                });
                            }}
                            className="drop-down-hamburger" />
                    </Tooltip>

                </div>}



                {clientAllConfig?.aiEnable && row.registeredUser && <div className='pe-2 '>
                    <Tooltip content="Generative Boost AI">
                        <ChatIcon demoClientToken={fromDemoUser ? demoClientToken : null} openFromProd={true} businessId={row.businessUserId} name={row.displayName} ></ChatIcon>
                    </Tooltip>
                </div>}

                <Tooltip content="More options">
                    <DownloadReportBtn
                        showAICopilet={row != null && (!fromDemoUser)}
                        isRegisterUser={row != null && row.registeredUser && (!fromDemoUser)}
                        showBTNDocs={row != null && row.registeredUser && (!fromDemoUser)}
                        showBTNDownloadExcel={row != null && (row.bankAccountDetails.length > 0) && row.registeredUser
                        }
                        showBTNDownload={row != null && (row.creditDetails.length > 0) && row.registeredUser}
                        clientAllConfig={clientAllConfig}
                        clickOnDownload={() => {
                            // console.log("row?.businessUserId " + row.businessUserId)
                            downloadReport(row.businessUserId);
                            // Event for download
                        }}
                        clickOnExcel={() => {
                            // console.log("row?.businessUserId " + row.businessUserId)
                            downloadExcel(row.businessUserId);
                            // Event for download excel
                        }}
                        commonOnClick={(clickFrom) => {
                            if (clickFrom === 'SDK_REQ_URL') {
                                opneSDKUATUser(false, row);
                            } else if (clickFrom === 'AI_LENDING_URL') {
                                getTemplateIDApi(row)
                            } else if (clickFrom === 'SUPPORTING_DOCS') {
                                showListOFDocs(row.businessUserId)
                            } else if (clickFrom === 'DOWNLOAD_EXCEL') {
                                downloadExcel(row.businessUserId, fromDemoUser);
                            } else if (clickFrom === 'DOWNLOAD_REPORT') {
                                downloadReport(row.businessUserId, fromDemoUser);
                            }

                        }}

                    />
                </Tooltip>

                {/* {(row != null && row.creditDetails.length == 0 && row.bankAccountDetails.length == 0) &&
          <div className='dropdown-btn dropdown-toggle-a'
            onClick={(e) => {
              console.log("row?.businessUserId " + row.businessUserId)
              openCreditReport(row?.businessUserId);
            }}>Upload Documents</div>
        } */}
            </div>
        </div>
    );
    //// columns for data tabels
    const columns = (fromDemoUser = false) => [
        {
            style: {
                display: 'flex',
                justifyContent: 'start',
            },
            sortable: false,
            cell: row => <UserName row={row} />,
        },

        {
            sortable: false,
            style: {
                display: 'flex',
                justifyContent: 'end',
            },
            cell: row => <DownloadUploadButton row={row} fromDemoUser={fromDemoUser} />,

        },
        {
            sortable: false,
            width: '3rem',
            style: {
                display: 'flex',
                justifyContent: 'center',
            },
            cell: row => <div data-tag="allowRowEvents">
                <img src={DownArrowIC} alt='UserListIC' className={row === currentRow ? 'down-arrow-ic custom-rotate-180' : 'down-arrow-ic'}
                    data-tag="allowRowEvents" />
            </div>,
        },

    ];

    const FipTspModel = (props) => {
        return <div className='tsp-iframe-popup text-center'>
            {/* <span id="close-icon" className={classOnLoadAA ? 'bi bi-x tsp-close-btn ' + classOnLoadAA : 'bi bi-x tsp-close-btn'} onClick={() => {
            closeTspPopup()
        }}></span> */}
            <iframe
                allowFullScreen
                height="650px"
                src={props.src}
                width="100%"
                id="tspiframe"
                allow='popup'
                sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
            />
        </div>
    }

    const openSDKUAT = async () => {
        setOpenSDKIframe(true)
        await generateB2BSDKLink(true)
        setCallSDKB2B(true)
    }

    const generateB2BSDKLink = async (isOpenSdk = false) => {
        setLoader(true)
        getCommonProdClientTokenB2B(
            async () => {
                if (isOpenSdk) {
                    const redirectUrl = config.prodApiUrl + 'prod-users';
                    //const redirectUrl = 'http://localhost:3000/users';

                    // Get SDK URL
                    getSDKProdUrl().then((res) => {
                        if (res.statusCode === "SUCCESS") {
                            const overrideSdkOnboardingOtpByPass = '&overrideSdkOnboardingOtpByPass=true';
                            const isIframe = '&isIframe=true';
                            var urlData = res.sdkUrl.split('?')
                            // var sdkUrl = 'http://localhost:3005/sdk/onboard?' + urlData[1] + overrideSdkOnboardingOtpByPass + isIframe //Local Path
                            // var sdkUrl = 'http://localhost:3005/sdk/onboard?' + urlData[1] + '&redirectUrl=' + redirectUrl + overrideSdkOnboardingOtpByPass + openFromIframe 
                            var sdkUrl = res.sdkUrl + '&redirectUrl=' + redirectUrl + overrideSdkOnboardingOtpByPass + isIframe //Server Path
                            // window.open(sdkUrl, '_blank', 'noreferrer');
                            setB2BLink(sdkUrl)
                            setLoader(false)
                        }
                    }).catch((error) => {
                        setLoader(false)
                        console.log(error?.response.data.errorMessage)
                    })
                } else {
                    setLoader(false)
                    setIsOpenTestUserDialog(true);
                }
            },
            (errorMessage) => {
                setLoader(false)
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const opneSDKUATUser = async (isOpenURL, rowData) => {

        generateClientTokenUATB2B(true, isOpenURL, rowData);
    };

    const closeLinkDialog = () => {
        setOpenLinkDialog({ isOpen: false, link: '', label: '' });
    };

    const closeGenLinkDialog = () => {
        setOpenGenReqURLDialog({
            isOpen: false,
            dataRow: {
                "businessUserId": null,
                "firstName": null,
                "lastName": null,
                "mobileNumber": null,
                "email": null,
            }
        });
    };


    const generateClientTokenUATB2B = async (isOpenSdk = false, isOpenURL = false, rowData = null) => {
        setLoader(true)
        getCommonProdClientTokenB2B(
            async () => {
                if (isOpenSdk) {
                    const redirectUrl = config.prodApiUrl + 'users';
                    //const redirectUrl = 'http://localhost:3000/users';

                    // Get SDK URL
                    getSDKProdUrl().then((res) => {
                        setLoader(false)
                        if (res.statusCode === "SUCCESS") {
                            const overrideSdkOnboardingOtpByPass = '&overrideSdkOnboardingOtpByPass=true';
                            var urlData = res.sdkUrl.split('?')
                            //console.log(res.sdkUrl)

                            const urlObj = new URL(res.sdkUrl);
                            if (rowData !== null && rowData.businessUserId !== null) {
                                urlObj.searchParams.set("businessUserId", rowData.businessUserId);
                            }
                            let qStringUserData = '';
                            if (rowData !== null) {
                                qStringUserData = Object.entries(rowData)
                                    .filter(([key, value]) =>
                                        ["firstName", "lastName", "mobileNumber", "email"].includes(key) && value !== undefined && value !== null
                                    )
                                    .map(([key, value]) => key === 'email' ? `&${'emailId'}=${value}` : `&${key}=${encodeURIComponent(value)}`)
                                    .join('');

                            }



                            //var sdkUrl = 'http://localhost:3005/sdk/onboard?' + urlData[1] + '&redirectUrl=' + redirectUrl  //Local Path
                            var sdkUrl = urlObj.toString() + '&redirectUrl=' + redirectUrl + overrideSdkOnboardingOtpByPass + qStringUserData //Server Path

                            if (isOpenURL) {
                                window.open(sdkUrl, '_self', 'noreferrer');
                            } else {
                                setOpenLinkDialog({ isOpen: true, 'link': sdkUrl, label: 'SDK URL' });
                            }

                        }
                    }).catch((error) => {
                        setLoader(false)
                        console.log(error?.response.data.errorMessage)
                    })
                } else {
                    setLoader(false)
                    setIsOpenTestUserDialog(true);
                }
            },
            (errorMessage) => {
                setLoader(false)
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const DownloadGSTExcelReport = (businessId,) => {
        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId,
        }
        const uuid = uuidv4();
        getCommonProdClientTokenB2B(
            () => {
                gstExcelDownloadProdApi(param, uuid)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'gst_excel_analysis_report.xls';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the Excel file:', error);
                    })
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const getTemplateIDApi = (row) => {
        setLoader(true);

        getCommonProdClientTokenB2B(
            () => {
                getAllTemplateProdApi()
                    .then(response => {
                        if (response.statusCode === 'SUCCESS') {
                            if (response.data != null && response.data.length > 0) {
                                let templates = response.data[0].templates;
                                let salariedEmployeeTemplateId = null;
                                let selfEmployeeTemplateId = null;

                                if (templates && Array.isArray(templates)) {
                                    let salariedTemplate = templates.find(template => template.employmentType === "Salaried" || template.employmentType === "Salaried Employee"
                                        || template.employmentType.toLowerCase().includes("salaried")
                                    );

                                    salariedEmployeeTemplateId = salariedTemplate ? salariedTemplate.templateId : null;

                                    let selfTemplate = templates.find(template => template.employmentType === "Self" || template.employmentType === "Self Employee"
                                        || template.employmentType.toLowerCase().includes("self")
                                    );

                                    selfEmployeeTemplateId = selfTemplate ? selfTemplate.templateId : null;
                                }
                                ApiSendCopilate(row, salariedEmployeeTemplateId, selfEmployeeTemplateId);
                            } else {
                                setLoader(false);
                            }
                        } else {

                            setLoader(false);
                        }
                    }).catch(error => {
                        //  console.log(error)
                        if (error && error.response && error.response.data) {
                            toast(error.response.data.statusMessage, {
                                autoClose: 1000,
                                toastId: Math.random(), progressStyle: {
                                    background: 'red', // Change this to the desired color
                                },
                                position: toast.POSITION.TOP_RIGHT, className: 'toast-message'
                            });
                        }

                        setLoader(false);
                    });
            },
            (errorMessage) => {

            }
        );

    }

    const ApiSendCopilate = (row, salariedEmployeeTemplateId, selfEmployeeTemplateId) => {

        const param = {
            "businessUserId": row.businessUserId === undefined || row.businessUserId === null ? null : row.businessUserId,
            "firstName": row.firstName === undefined || row.firstName === null ? null : row.firstName,
            "lastName": row.lastName === undefined || row.lastName === null ? null : row.lastName,
            "mobileNumber": row.mobileNumber === undefined || row.mobileNumber === null ? null : row.mobileNumber,
            "email": row.email === undefined || row.email === null ? null : row.email,
            "salariedEmployeeTemplateId": salariedEmployeeTemplateId,
            "selfEmployeeTemplateId": selfEmployeeTemplateId
        }
        sendCopilateProdApi(param)
            .then(response => {
                setLoader(false);
                if (response.statusCode === 'SUCCESS') {
                    setOpenLinkDialog({ isOpen: true, 'link': response.url, label: 'AI Lending Copilot URL' });
                }
                //console.log(response);

            }).catch(error => {
                setLoader(false);
            })
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    useMemo(() => {
        if (filterText === '') {
            setUserListData(userListDataFilter);
        } else {
            const filteredItems = userListDataFilter.filter(item =>
                (item.displayName && item.displayName.toLowerCase().includes(filterText.toLowerCase()))
            );
            setUserListData(filteredItems);
        }


    }, [filterText, resetPaginationToggle]);



    /// expandalbe desgin inside of each cells

    const ExpandedRowWrapper = (row) => (
        <ExpandedComponent props={row} isFromDemoUser={false} />
    );

    const ExpandedRowWrapperDemoUser = (row) => (
        <ExpandedComponent props={row} isFromDemoUser={true} />
    );

    const ExpandedComponent = ({ props, isFromDemoUser }) => {

        const [isCreditShow, setCreditShow] = useState(true);
        return (
            <div className='m-0 p-0'>

                <div className='col-12 d-flex m-0 p-0 flex-wrap info-root-box p-3'>
                    <div className='col-12 p-0 m-0'>
                        <TabComponent index={1} onTabChange={(i) => {
                            setCreditShow(i === 0 ? true : false);
                        }} ></TabComponent>
                    </div>
                    {/* <p className='info-title m-0 p-0 ps-2 pt-2 pb-1'>Credit Report Information</p> */}
                    <div className='col-12 m-0 p-0 '>
                        <div className='row p-0 m-0'>
                            {props.data && props.data?.creditDetails != null && props.data?.creditDetails.length > 0 && <div className='dividers w-100'></div>}
                            {props.data && props.data?.creditDetails != null && isCreditShow &&
                                props.data?.creditDetails.map((creditDetail, index) => (
                                    // <div className='mb-2 bg-gray col-lg-4 col-md-4 col-sm-6 col-12' key={creditDetail.requestId + index}>

                                    // </div>
                                    <div className='col-lg-4 col-md-6 ps-1 m-0 pt-3 pt-lg-4 pt-md-3 pt-sm-3' key={creditDetail.requestId + index}>
                                        <div className='info-details-box'>
                                            <div className='row m-0 p-0 pt-2 pb-2'>
                                                <div className='col-12 d-flex justify-content-start'>
                                                    <p className='info-details-label-text m-0'>Credit Report {index + 1}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row col-12'>
                                                <div className='d-flex col-lg-12 flex-column'>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Name</p>
                                                        <p className='info-details-sub-text m-0'>{creditDetail.name ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-1'>
                                                        <p className='info-details-main-text m-0'>Status</p>
                                                        <p className={creditDetail && creditDetail.status == "SUCCESS" ? 'info-details-sub-text m-0 response-status' : 'info-details-sub-text m-0'}>{creditDetail.status ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Credit Bureau</p>
                                                        <p className='info-details-sub-text m-0'>{creditDetail.bureauName ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between  pt-2 pb-3'>
                                                        <p className='info-details-main-text m-0'>Last Updated</p>
                                                        <p className='info-details-sub-text m-0'>{moment(creditDetail?.updatedDate).format('DD-MM-YYYY') ?? 'N/A'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            {/* <div className='info-details-box m-2'> */}
                            {props.data?.creditDetails && (
                                <div className={
                                    `d-flex flex-column align-items-center justify-content-center pt-2 
                    ${window.innerWidth >= 768 && window.innerWidth < 992 ?
                                        (`${props.data?.creditDetails.length % 2 !== 0 ? 'col-md-6' :
                                            'col-lg-12 d-flex justify-content-center'}`)
                                        :
                                        `${props.data?.creditDetails.length % 3 !== 0 ? 'col-lg-4' :
                                            'col-lg-12 d-flex justify-content-center'}`}

                     ${props.data?.creditDetails.length == 0 ? 'pt-0' : 'pt-4'}`
                                }>
                                    {/* <p className='p-0 m-0 credit-report-que'>Have more credit reports?</p>
                  <div className='info-label-ic pt-2 m-0' onClick={() => { openCreditReport(props.data?.businessUserId) }}>
                    <img src={UploadIC} alt='info-upload-ic' className='info-upload-ic' />
                    <p className='info-upload-label'>Upload Credit Report </p>

                  </div> */}
                                    {props.data?.creditDetails.length == 0 && isCreditShow &&
                                        <p className='info-upload-label pt-2'>Credit Report is not available </p>}
                                </div>
                            )}
                            {/* </div> */}
                        </div>
                    </div>

                    {/*Credit information - END*/}
                    {/* <div className='dividers w-100 mt-4 mb-3'></div> */}
                    {/*Bank statement information - START*/}
                    {/* <p className='info-title m-0 p-0 ps-2 pt-2 pb-1'>Bank statement information</p> */}
                    <div className='col-12 m-0 p-0 '>
                        <div className='row m-0 p-0'>
                            {props.data && props.data?.bankAccountDetails != null && !isCreditShow &&
                                props.data?.bankAccountDetails.map((bankAccountDetail, index) => (
                                    <div className='col-lg-4 col-md-6 ps-1 m-0 p-0 mt-2' key={bankAccountDetail.requestId + index}>
                                        <div className='info-details-box'>
                                            <div className='row pt-2 pb-0'>
                                                <div className='col-12 d-flex justify-content-between'>
                                                    <p className='info-details-label-text m-0'>Bank Account {index + 1}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row col-12'>
                                                <div className='d-flex col-lg-12 flex-column'>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Name</p>
                                                        <p className='info-details-sub-text m-0'>{bankAccountDetail?.accountHolderName ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Account Number</p>
                                                        <p className='info-details-sub-text m-0'>{bankAccountDetail?.accountNumber ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-1'>
                                                        <p className='info-details-main-text m-0'>Status</p>
                                                        <p className={bankAccountDetail && bankAccountDetail.status == "SUCCESS" ? 'info-details-sub-text m-0 response-status' : 'info-details-sub-text m-0'}>{bankAccountDetail?.status ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Bank</p>
                                                        <p className='info-details-sub-text m-0'>{bankAccountDetail?.bankName ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Account Type</p>
                                                        <p className='info-details-sub-text m-0'>{bankAccountDetail?.accountType ?? 'N/A'}</p>
                                                    </div>
                                                    <div className='dividers p-0 m-0'></div>
                                                    <div className='d-flex justify-content-between pt-2 pb-2'>
                                                        <p className='info-details-main-text m-0'>Last Updated</p>
                                                        <p className='info-details-sub-text m-0'>{moment(bankAccountDetail?.updatedDate).format('DD-MM-YYYY') ?? 'N/A'}</p>
                                                    </div>

                                                    {(clientAllConfig && clientAllConfig?.bankAccountExcelAnalysisReport &&
                                                        <div className='dividers p-0 m-0'></div>
                                                    )}

                                                    {(clientAllConfig && clientAllConfig?.bankAccountExcelAnalysisReport &&
                                                        <div className='d-flex justify-content-between pt-2 pb-3'>
                                                            <p className='info-details-main-text m-0'>Excel Analysis</p>
                                                            <div className='info-details-sub-text m-0'>
                                                                {clientAllConfig && clientAllConfig?.bankAccountExcelAnalysisReport &&
                                                                    <div className='info-label-ic p-0 m-0' onClick={() => {
                                                                        if (bankAccountDetail?.accountUID) {
                                                                            downloadExcelAnalysis(props.data?.businessUserId,
                                                                                bankAccountDetail?.accountUID);
                                                                        }
                                                                    }}>
                                                                        <img src={DownloadIC} alt='info-upload-ic' className='info-upload-ic-mini' />
                                                                        <p className='info-upload-label p-0 m-0 ps-1'>Download</p>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {props.data?.bankAccountDetails && (
                                <div className={
                                    `d-flex flex-column align-items-center justify-content-center pt-2 
                    ${window.innerWidth >= 768 && window.innerWidth < 992 ?
                                        (`${props.data?.bankAccountDetails.length % 2 !== 0 ? 'col-md-6' :
                                            'col-lg-12 d-flex justify-content-center'}`)
                                        :
                                        `${props.data?.bankAccountDetails.length % 3 !== 0 ? 'col-lg-4' :
                                            'col-lg-12 d-flex justify-content-center'}`}

                     ${props.data?.bankAccountDetails.length == 0 ? 'pt-0' : 'pt-4'}`
                                }>
                                    {props.data?.bankAccountDetails.length == 0 && !isCreditShow &&
                                        <p className='info-upload-label'>Bank Statement is not available </p>}
                                    {/* <p className='p-0 m-0 credit-report-que'>Have more bank statements?</p>
                  <div className='info-label-ic pt-2 m-0' onClick={() => { openBankStatement(props.data?.businessUserId) }}>
                    <img src={UploadIC} alt='info-upload-ic' className='info-upload-ic' />
                    <p className='info-upload-label'>Bank Statement is not available </p>
                  </div> */}
                                </div>
                            )}
                        </div>
                    </div>

                    {/*Bank statement information - END*/}
                    {clientAllConfig?.gstEnable && <div className='dividers w-100 mt-4 mb-3'></div>}
                    {/*GST statement information - START*/}

                    {clientAllConfig?.gstEnable && <div className='col-12 '>
                        <p className='info-title m-0 p-0 ps-2 pt-2 pb-1'>GST information</p>
                        <div className='col-12 m-0 p-0 '>

                            <div className='row'>
                                {props.data && props.data?.gstDetails != null &&
                                    props.data?.gstDetails.map((gstDetails, index) => (
                                        <div className='col-lg-4 col-md-6 ps-1 m-0 pt-3 pt-lg-3 pt-md-3 pt-sm-3' key={gstDetails.requestId + index}>
                                            <div className='info-details-box'>
                                                <div className='row pt-2 pb-0'>
                                                    <div className='col-12 d-flex justify-content-between'>
                                                        <p className='info-details-label-text m-0'>GST Account {index + 1}</p>

                                                    </div>
                                                </div>
                                                <div className='dividers'></div>
                                                <div className='row col-12'>
                                                    <div className='d-flex col-lg-12 flex-column'>
                                                        <div className='d-flex justify-content-between pt-2 pb-2'>
                                                            <p className='info-details-main-text m-0'>Lender Name</p>
                                                            <p className='info-details-sub-text m-0'>{gstDetails && gstDetails.name != null ? gstDetails.name : 'N/A'}</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-2'>
                                                            <p className='info-details-main-text m-0'>GST No.</p>
                                                            <p className='info-details-sub-text m-0'>{gstDetails && gstDetails.gstNumber != null ? gstDetails.gstNumber : 'N/A'}</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-2'>
                                                            <p className='info-details-main-text m-0'>GST Types</p>
                                                            <p className='info-details-sub-text m-0'>{gstDetails && gstDetails.gstName != null ? gstDetails.gstName.replace(',', ' &').replace(/[\[\]']+/g, '') : 'N/A'}</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-1'>
                                                            <p className='info-details-main-text m-0'>Status</p>
                                                            <p className={gstDetails && gstDetails.status == "SUCCESS" ? 'info-details-sub-text m-0 response-status' : 'info-details-sub-text m-0'}>{gstDetails?.status ?? 'N/A'}</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-2'>
                                                            <p className='info-details-main-text m-0'>Last Updated</p>
                                                            <p className='info-details-sub-text m-0'>{moment(gstDetails?.uploadedDate).format('DD-MM-YYYY') ?? 'N/A'}</p>
                                                        </div>
                                                        <div className='dividers p-0 m-0'></div>
                                                        <div className='d-flex justify-content-between pt-2 pb-3'>
                                                            <p className='info-details-main-text m-0'>Correlation Excel</p>
                                                            <div className='info-details-sub-text m-0 p-0'>
                                                                {
                                                                    <div className='info-label-ic p-0 m-0' onClick={() => {
                                                                        DownloadGSTExcelReport(props.data?.businessUserId)
                                                                    }}>
                                                                        <img src={DownloadIC} alt='info-upload-ic' className='info-upload-ic-mini' />
                                                                        <p className='info-upload-label p-0 m-0 ps-1'>Download</p>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                {props.data?.gstDetails && (
                                    <div className={
                                        `d-flex flex-column align-items-center justify-content-center pt-2 
                    ${window.innerWidth >= 768 && window.innerWidth < 992 ?
                                            (`${props.data?.gstDetails.length % 2 !== 0 ? 'col-md-6' :
                                                'col-lg-12 d-flex justify-content-center'}`)
                                            :
                                            `${props.data?.gstDetails.length % 3 !== 0 ? 'col-lg-4' :
                                                'col-lg-12 d-flex justify-content-center'}`}

                     ${props.data?.gstDetails.length == 0 ? 'pt-0' : 'pt-4'}`
                                    }>
                                        <p className='p-0 m-0 credit-report-que'>Have more GST statements?</p>
                                        <div className='info-label-ic pt-2 m-0' onClick={() => { openGSTUpload(props.data?.businessUserId) }}>
                                            <img src={UploadIC} alt='info-upload-ic' className='info-upload-ic' />
                                            <p className='info-upload-label'>Upload GST Document</p>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>}


                    {!isFromDemoUser &&
                        ((props.data && props.data?.creditDetails != null && props.data?.creditDetails.length > 0 && isCreditShow)
                            || (props.data && props.data?.bankAccountDetails != null && props.data?.bankAccountDetails.length > 0 && !isCreditShow)) &&
                        < div className='col-12 d-flex flex-row justify-content-end'>
                            <button type='button' style={{ fontSize: '15px', padding: '12px 16px' }} className='next-button mx-1 my-0'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentTabIndex(isCreditShow ? 0 : 1)
                                    let data = {
                                        'businessUserId': props.data.businessUserId,
                                        'isOpnePanel': true,
                                        'firstName': props.data.firstName,
                                        'lastName': props.data.lastName,
                                        'mobileNumber': props.data.mobileNumber,
                                        'salariedEmployeeTemplateId': props.data.mobileNumber,
                                        'selfEmployeeTemplateId': props.data.selfEmployeeTemplateId,
                                        'fullName': props.data.firstName + " " + props.data.lastName,
                                        'date': props.data.createdDate != null ? moment(props.data.createdDate).format('DD-MM-YYYY h:mm A') : '-',
                                    };
                                    // console.log("props.data.businessUserId ", data)

                                    setPanelData(data);
                                }}>{isCreditShow ? `View Credit Report` : `View Bank Data`}<span className='ps-2'><img src={RTArrowWT}

                                    className='rt-arrow-wt' /></span>
                            </button>
                        </div>}


                </div>
            </div >
        )
    };


    return (
        <>
            {loader && <Loader />}
            <div id="sdk" className='mt-4 mt-sm-2'>
                <section className='' id='uatenv'>
                    <div className='container-lg container-fluid mt-3 my-sm-3'>
                        <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                            <div className='col-5'>
                                <div className='ps-sm-2'>
                                    <h1 className="username-text  mb-2 mb-sm-3">
                                        Hi {userData?.name}!
                                    </h1>
                                    <p className="welcome-back-text mb-1" >
                                        Welcome to Boost.Money
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <section>
                <ErrorMessage message={message} />
            </section>
            <div className='mb-4'>
                {/* <div className='container-lg container-fluid my-md-4'>
          <div className='bank-state-box'>
            <div className='row'>
              <div className='co-12 text-center '>
                <h1 className='boosted-score-text text-uppercase'>Get boost report and transactional excel report</h1>
                <p className='boosted-moneytetx'>Share the Credit Report and bank statements to get the reports</p>
              </div>
            </div>
            <div className='row mt-3 g-2 g-sm-4'>
              <div className='col-sm-12 col-lg-4 px-4'>
                <div className='bankbox-card'>
                  <div className='bankbox-inner-card g-2'>
                    <h4 className='numbers'>01.</h4>
                    <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                      <img src={AddBank} className='img-info' />
                    </div>
                    <p className='get-score-images-text text-break '>Share your credit report & bank statement</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                <div className='bankbox-card'>
                  <div className='bankbox-inner-card g-2'>
                    <h4 className='numbers'>02.</h4>
                    <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                      <img src={GetInsights} className='img-info' />
                    </div>
                    <p className='get-score-images-text text-break'>Let us analyze your credit report and bank statement</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                <div className='bankbox-card'>
                  <div className='bankbox-inner-card g-2'>
                    <h4 className='numbers'>03.</h4>
                    <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                      <img src={Finances} className='img-info' />
                    </div>
                    <p className='get-score-images-text text-break'>Get Boost Report and excel transactions report</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 p-0 mt-3 g-3'>
              <div className='col-12  text-center my-3'>
                <button className='mb-0 get-started-btn' onClick={() => { opneSDKUATUser(true, null) }}>Try SDK</button>
              </div>

             

            </div>
          </div>
        </div> */}

                <div>
                    {openSDKIframe &&
                        <div className='open-b2bsdk'>
                            <ReactDialogBox
                                headerBackgroundColor='white'
                                headerTextColor='white'
                                closeButtonColor='white'
                                bodyBackgroundColor='white'
                                bodyTextColor='white'
                                bodyHeight={dialogHeight}
                                borderRadius='0px'
                                headerHeight='0'
                            >
                                {callSDKB2B ?
                                    <FipTspModel src={b2bLink} />
                                    : null
                                }
                            </ReactDialogBox>
                        </div>
                    }

                    {isOpenTestUserDialog && (
                        <ReactDialogBox
                            modalWidth='auto'
                            closeBox={closeTestUserDialog}
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            headerHeight='65px'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight={dialogHeight}
                            headerText={dialogTestUserTitle}
                            borderRadius='8px'>
                            <CreateCustomer
                                openFromProd={true}
                                closeTestUserDialog={closeTestUserDialog}
                                setBusinessId={setBusinessId}
                                getMainUserList={getMainUserList}
                                setScore={props.setScore}
                                setAnalysisReportData={props.setAnalysisReportData}
                                setDialogTitle={setDialogTestUserTitle}
                                setDialogHeight={setDialogHeight}
                                setIsOpenDialog={setIsOpenDialog}
                                setOpenGenReqURLDialog={setOpenGenReqURLDialog}
                                scrollToTop={props.scrollToTop}
                                setCreditReportData={props.setCreditReportData}
                                bureauDataGenerate={props.bureauDataGenerate}
                                setMessage={props.setMessage} />
                        </ReactDialogBox>)}

                    {isOpenDialog && (
                        <ReactDialogBox
                            modalWidth='375px'
                            closeBox={closeDialog}
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            headerHeight='65px'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight={dialogHeight}
                            headerText={dialogTitle}
                            borderRadius='8px'
                        >
                            <CreditReport closeDialog={closeDialog}
                                openFromProd={true}
                                businessId={businessId}
                                setScore={props.setScore}
                                isShowSkipBtn={isShowSkipBtn}
                                getMainUserList={getMainUserList}
                                scrollToTop={props.scrollToTop}
                                setMessage={setMessage}
                                setDialogTitle={setDialogTitle}
                                setDialogHeight={setDialogHeight}
                                setIsOpenDialog={setIsOpenDialog}
                                setIsOpenBankStmDialog={setIsOpenBankStmDialog}
                            />
                        </ReactDialogBox>)}

                    {isOpenBankStmDialog && (
                        <ReactDialogBox
                            closeBox={closeBankStmDialog}
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            headerHeight='65px'
                            modalWidth='400px'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight={dialogHeight}
                            headerText={dialogBankStmTitle}
                            borderRadius='8px'
                        >

                            <BankStatmentForm
                                openFromProd={true}
                                closeBankStmDialog={closeBankStmDialog}
                                setIsOpenBankStmDialog={setIsOpenBankStmDialog}
                                scrollToTop={props.scrollToTop}
                                setDialogBankStmTitle={setDialogBankStmTitle}
                                setDialogHeight={setDialogHeight}
                                setMessage={setMessage}
                                businessId={businessId}
                                isShowSkipBtn={isShowSkipBtn}
                                getMainUserList={getMainUserList}
                                openMultipleBankStatement={openMultipleBankStatement}
                                openStatmentResSPFDialog={openStatmentResSPFDialog}
                                setFiuMultiBankSPF={setFiuMultiBankSPF}
                                setFiuPartialMsg={setFiuPartialMsg}

                            />
                        </ReactDialogBox>)}

                    {openMultipleScreen && (
                        <ReactDialogBox
                            closeBox={closeMultiBankStmDialog}
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            headerHeight='65px'
                            modalWidth='720px'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight='550px'
                            headerText={dialogBankStmTitle}
                            borderRadius='8px'
                        >

                            <BankStatmentMultiple
                                openFromProd={true}
                                closeBankStmDialog={closeMultiBankStmDialog}
                                setOpenMultipleScreen={setOpenMultipleScreen}
                                scrollToTop={props.scrollToTop}
                                setDialogBankStmTitle={setDialogBankStmTitle}
                                setDialogHeight={setDialogHeight}
                                setMessage={setMessage}
                                businessId={businessId}
                                isShowSkipBtn={isShowSkipBtn}
                                getMainUserList={getMainUserList}
                                openMultipleBankStatement={openMultipleBankStatement}
                                openStatmentResSPFDialog={openStatmentResSPFDialog}
                                setFiuMultiBankSPF={setFiuMultiBankSPF}
                                setFiuPartialMsg={setFiuPartialMsg}

                            />
                        </ReactDialogBox>)}

                    {openGSTDialog && (
                        <ReactDialogBox
                            modalWidth='385px'
                            closeBox={closeDialog}
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            headerHeight='65px'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight={dialogHeight}
                            headerText={dialogTitle}
                            borderRadius='8px'
                        >
                            <GSTUploadDocuments closeDialog={closeDialog}
                                openFromProd={true}
                                businessId={businessId}
                                setScore={props.setScore}
                                isShowSkipBtn={isShowSkipBtn}
                                getMainUserList={getMainUserList}
                                scrollToTop={props.scrollToTop}
                                setMessage={setMessage}
                                setDialogTitle={setDialogTitle}
                                setDialogHeight={setDialogHeight}
                                setIsOpenDialog={setIsOpenDialog}
                                setIsOpenBankStmDialog={setIsOpenBankStmDialog}
                            />
                        </ReactDialogBox>)}

                    {openResSPFDialog && <Modal open={true} center classNames={{
                        modal: 'spf-modal',
                    }}>
                        <SuccessFailDialog
                            fiuMultiBankSPF={fiuMultiBankSPF}
                            fiuPartialMsg={fiuPartialMsg}
                            closeStatmentResSPFDialog={closeStatmentResSPFDialog}>
                        </SuccessFailDialog>
                    </Modal>}

                    {isOpenGenReqURLDialog.isOpen && (
                        <div className='document-dialog'>
                            <CustomDialog
                                isOpen={isOpenGenReqURLDialog.isOpen}
                                closeBox={closeGenLinkDialog}
                                headerText={'Generate Request'}
                                bodyHeight={dialogHeight}>

                                <GenReqDialogContent
                                    clientAllConfig={clientAllConfig}
                                    handleGenerateURL={(selectedBox) => handleGenerateURL(isOpenGenReqURLDialog.dataRow, selectedBox,)}
                                >
                                </GenReqDialogContent>
                            </CustomDialog>
                        </div >)
                    }

                    {openLinkDialog.isOpen && (
                        <div className='document-dialog'>
                            <CustomDialog
                                isOpen={openLinkDialog.isOpen}
                                closeBox={closeLinkDialog}
                                headerText={openLinkDialog.label}
                                bodyHeight={dialogHeight}>
                                <ShareURLDialogContent link={openLinkDialog.link} />
                            </CustomDialog>
                        </div >)
                    }
                </div>
            </div>



            <section id='userslist'>
                <div className='container-lg container-fluid my-3'>
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                <div className='w-100'>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <p className="acc-info-text" >Users List</p>
                                        </div>
                                    </div>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                <div className="accordion-body p-0 m-0">
                                    <div className='row  m-0 p-0'>
                                        <div className='col-12 m-0 p-0'>
                                            <div className='m-0 p-0 pb-3 px-3 d-flex flex-wrap align-items-center'>
                                                <div className='flex-grow-1 m-0 p-0 pt-3 '>
                                                    <FilterComponent
                                                        onFilter={e => setFilterText(e.target.value)}
                                                        onClear={handleClear}
                                                        filterText={filterText}
                                                    />
                                                </div>
                                                <div className='col-12 col-lg-2 col-md-3 col-sm-3 m-0 p-0 pt-3 text-center ms-md-3'>
                                                    <div className='mb-0 m-0 add-user-btn' onClick={() => { openTestUser() }}>+ Add New User</div>
                                                </div>
                                            </div>
                                            <div className='account-accordion-box'>
                                                <div className="accordion ps-3 pe-3" id="accordionFlushExample">

                                                    {userListData && <DataTable
                                                        title="Users"
                                                        columns={columns(false)}
                                                        data={userListData}
                                                        noHeader={true}
                                                        customStyles={customStyles}
                                                        noTableHead={true}
                                                        expandableRows
                                                        expandOnRowClicked={true}
                                                        expandableRowsComponent={ExpandedRowWrapper}
                                                        pagination
                                                        paginationServer={true}
                                                        paginationPerPage={sizePerPage}
                                                        paginationTotalRows={totalRecordFromApi}
                                                        onChangePage={handlePerRowsChange}
                                                        paginationComponent={(props) => (
                                                            <PaginationComponent {...props} {...extraProps} />
                                                        )}
                                                        onRowExpandToggled={(bool, row) => {
                                                            if (row === currentRow) {
                                                                setCurrentRow(null); // Collapse the row if it's already expanded
                                                            } else {
                                                                setCurrentRow(row); // Expand the row if it's collapsed
                                                            }
                                                        }}
                                                        expandableRowExpanded={(row) => (row === currentRow)}
                                                        onRowClicked={(row) => setCurrentRow(row)}
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {listOfDocShow.isOpenPopup &&
                    <div className='docs-list-dialog'>
                        <ReactDialogBox
                            closeBox={closeListOfDocPopup}
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight={dialogHeight}
                            borderRadius='8px'
                            headerHeight='65px'
                            headerText="SUPPORTING DOCUMENTS">

                            <ListDocComponent
                                listOfDocs={listOfDocs}
                                downloadDocuments={() => {
                                    downloadLendingDocs(listOfDocShow.docBusinessUserId)
                                }}
                            />
                        </ReactDialogBox>
                    </div>}
            </section>

            <section>
                <SlidingPanel
                    width={window.innerWidth <= 768 ? "90%" : "80%"}
                    from='right'
                    panelData={panelData}
                    hideHeader={false}
                    isOpen={panelData.isOpnePanel}
                    title={panelData.fullName}
                    subtitle={panelData.date}
                    callAPITamplateID={(pData) => {
                        // getTemplateIDApi(pData);
                    }}
                    onPassLink={(link) => {
                        // setOpenLinkDialog({ isOpen: true, 'link': link });
                    }}
                    onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click

                        setPanelData(prevState => ({
                            ...prevState,
                            'isOpnePanel': false
                        }));
                    }}>

                    <section>
                        <div className='p-0 m-0 flex-column'>
                            <div className='pb-3'>
                                <TabComponent tabIndex={currentTabIndex} onTabChange={handleTabChange} ></TabComponent>
                            </div>
                            <div className='mt-2'>
                                {currentTabIndex === 0 && <CreditTabComponent openFromProd={true} panelData={panelData} businessUserId={panelData.businessUserId}></CreditTabComponent>}
                                {currentTabIndex === 1 && <BoostTabComponent openFromProd={true} panelData={panelData} businessUserId={panelData.businessUserId}></BoostTabComponent>}
                                {currentTabIndex === 2 && <SubTabComponent businessUserId={panelData.businessUserId}></SubTabComponent>}
                            </div>
                        </div>
                    </section>
                </SlidingPanel>
            </section>


        </>
    );
}
export default ProdUsersList;
