import { Link } from "react-router-dom";
const NoFoundIC = require("../../images/assets/no-found.png");

const PageNotFound = () => {
    return (
        <div style={styles.container}>
            {/* <h1 style={styles.errorCode} className="mt-2">404</h1> */}
            <img src={NoFoundIC} alt="no-found" style={styles.noFoundImg} />

            <h2 style={styles.message} className="mt-2">Oops! Page Not Found</h2>
            <p style={styles.description} className="mt-2">
                The page you're looking for doesn't exist or has been moved.
            </p>
            <div className="mt-4">
                <Link to="/" className="next-button px-3 py-2">
                    Go Back Home
                </Link>
            </div>
        </div>
    );
};

const styles = {
    noFoundImg: {
        width: "24.0rem",
        height: "auto",
    },
    container: {
        textAlign: "center",
        padding: "50px",
        backgroundColor: "#f8f9fa",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    errorCode: {
        fontFamily: 'Roboto',
        fontSize: "100px",
        fontWeight: "bold",
        color: "#dc3545",
        margin: "0",
    },
    message: {
        fontFamily: 'Martel Sans',
        fontSize: "28px",
        color: "#333",
        marginBottom: "10px",
    },
    description: {
        fontFamily: 'Martel Sans',
        fontSize: "18px",
        color: "#666",
        marginBottom: "20px",
    },

};

export default PageNotFound;
