import React from 'react'
import '../../../App.css';
import { useEffect, useState } from 'react';
import Footer from '../../landing-page/Footer/Footer';
import Loader from '../../loader/loader';
import { Link, useNavigate } from 'react-router-dom';
import { validateForgotPassword } from '../../../redux/actions/user_action';
import ErrorMessage from '../../Dashboard/error-message/error-message';
import PasswordValidation from '../createanaccount/password-validation';

const Logo = require("../../../images/assets/Logo.png");
const IC_EYE = require("../../../images/assets/show-password.png");
const IC_EYE_HIDE = require("../../../images/assets/hide-password.png");

const ForgotPassword = () => {
    const [passwrd, setPassword] = useState("")
    const [chpasswrd, setchPassword] = useState("")
    const [loader, setLoader] = useState(false)
    const [showError, setShowError] = useState(false)
    const [checkPass, setCheckPass] = useState(false)
    const [message, setMessage] = useState();
    const navigate = useNavigate();

    const [isFocused, setIsFocused] = useState(false); // Track focus state
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
    const [requirements, setRequirements] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        number: false,
        noSpaces: false,
    });

    const btnEnableDisable = {
        opacity: newPassword === '' || !Object.values(requirements).every(Boolean)
            || confirmPassword === '' || (confirmPassword !== newPassword) ? 0.5 : 1.0
    };

    const resetPassword = () => {
        setMessage('')
        var url = new URL(window.location.href);
        var searchParams = url.searchParams;
        var tokenAc = searchParams.get('token');
        if (tokenAc) {
            if (newPassword === '' || confirmPassword === '') {
                setShowError(true)
            } else if (newPassword !== confirmPassword) {
                setCheckPass(true)
            } else {
                const params = {
                    forgotPasswordToken: tokenAc,
                    password: newPassword,
                    confirmPassword: confirmPassword
                }
                validateForgotPassword(params).then(resp => {
                    if (resp.statusCode === "SUCCESS") {
                        //setMessage({"success" : resp.statusMessage})
                        navigate('/password-changed')

                    }
                }).catch(error => {
                    setLoader(false);
                    if (error?.response.data.errorCode === "B2B_FORGOT_PASSWORD_VALIDATION_400") {
                        setMessage(error?.response.data.errorMessage)
                    }
                    if (error && error?.response && error?.response.data) {
                        setMessage(error?.response.data.errorMessage)
                    } else {
                        setMessage('Something went wrong!');
                    }
                })
            }
        } else {
            setMessage('Token is missing');
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            resetPassword()
        }
    };
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);

        // Check each requirement
        setRequirements({
            length: newPassword.length >= 8 && newPassword.length <= 20,
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
            number: /[0-9]/.test(newPassword),
            noSpaces: !/\s/.test(newPassword),
        });
    };
    const handleFocus = () => {
        setIsFocused(true);  // Set focus to true when input is focused

    };

    const handleBlur = () => {
        setShowError(true);
        // Set focus to false when input is blurred
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>

                                <div>
                                    <h1 className='color'>
                                        Change your account password
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mb-md-0'>
                                        Set New Password
                                    </h1>
                                    <p className='fw-semibold p-0 m-0 mt-md-3 '>
                                        Set your new password for Boost.Money so you can login to your account
                                    </p>

                                    <div className='form-input-box m-0 p-0 mt-4'>
                                        <form>


                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>New Password</div>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        onFocus={handleFocus}  // Focus event handler
                                                        onBlur={handleBlur}    // Blur event handler
                                                        placeholder='Create password'
                                                        type={isRevealNewPwd ? "text" : "password"}
                                                        id='new-password' className='input-box form-control' value={newPassword}
                                                        onChange={(e) => { handlePasswordChange(e) }}
                                                        onKeyDown={handleKeyDown} />

                                                    <span>
                                                        <img title={isRevealNewPwd ? "Hide password" : "Show password"}
                                                            src={isRevealNewPwd ? IC_EYE_HIDE : IC_EYE}
                                                            onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                                            className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1"
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>

                                                </div>
                                                {/* {(showError && newPassword === '') && <span className="demo-tetx">Please enter your password</span>} */}
                                            </div>

                                            {isFocused && <PasswordValidation requirements={requirements} />}
                                            <div className='mt-3'></div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Confirm Password</div>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <input

                                                        placeholder='Confirm password'
                                                        type={isRevealConfirmPwd ? "text" : "password"}
                                                        id='confirm-password' className='input-box form-control' value={confirmPassword}
                                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                                        onKeyDown={handleKeyDown} />

                                                    <span>
                                                        <img title={isRevealConfirmPwd ? "Hide password" : "Show password"}
                                                            src={isRevealConfirmPwd ? IC_EYE_HIDE : IC_EYE}
                                                            onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}
                                                            className="eye-icon col-1 col-lg-1 col-md-2 col-sm-1"
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>

                                                </div>
                                                {(showError && confirmPassword === '') && <span className="demo-tetx">Please enter confirm password</span>}
                                            </div>

                                            {message !== '' && <span className="demo-tetx mt-2">{message}</span>}



                                            {(checkPass) && <span className="demo-tetx">Please verify, New password doesn't match with the confirm password</span>}


                                            <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='align-items-center p-0 m-0 d-flex flex-row justify-content-end 
                                                         px-1 px-md-0 px-sm-0 px-lg-0'>

                                                    <div className='order-1'>
                                                        <div className='text-end my-2 my-sm-0'>
                                                            <button
                                                                style={btnEnableDisable}
                                                                type='button' className='next-button mx-0'
                                                                onClick={() => {
                                                                    resetPassword();
                                                                }}>Reset Password</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default ForgotPassword